import Button from '@mui/material/Button'
import Box from '@mui/material/Box'
import { Close } from '@mui/icons-material'
import Typography from '@mui/material/Typography'
import Dialog from '@mui/material/Dialog'
import { useState } from 'react'
import DialogTitle from '@mui/material/DialogTitle'
import DialogContent from '@mui/material/DialogContent'
import DialogActions from '@mui/material/DialogActions'
import IconButton from '@mui/material/IconButton'
import { useHistory } from 'react-router'

const ListTrips = ({ data, onDelete }) => {
  const { name, image, id } = data
  const [open, setOpen] = useState(false)
  const history = useHistory()
  const style = {
    root: {
      position: 'relative',
      padding: '20px',
      boxShadow: '3px 3px 20px 1px rgba(0,0,0,.1)',
      marginBottom: '20px',
      display: 'flex'
    },
    title: {
      fontSize: '20px',
      fontWeight: '600',
      textTransform: 'uppercase'
    },
    date: {},
    buttons: {
      position: 'absolute',
      right: '20px',
      top: '0',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-end',
      height: '100%'
    },
    buttonEdit: {},
    buttonDelete: {
      background: '#cd3838',
      marginRight: '20px'
    },
    icon: {
      marginRight: '15px',
      height: '100%',
      fontSize: '30px',
      color: 'white',
      borderRadius: '50%',
      padding: '2px 7px'
    }
  }
  const handleDelete = () => {
    setOpen(true)
  }
  const editTrip = () => {
    history.push(`/editTrip/${id}`)
  }
  const deleteElement = () => {
    setOpen(false)
    onDelete(id)
  }
  return (
    <div style={style.root} className="reservation">
      <Box>
        <img src={image} alt={name} style={{ height: '100%', maxWidth: '80px' }}/>
      </Box>
      {
        id && (
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-between',
              marginLeft: '40px'
            }}
          >
            <Typography sx={{ fontWeight: '600', textTransform: 'uppercase' }}>{name}</Typography>
            <Typography><b>ID:</b> {id}</Typography>
          </Box>
        )
      }
      <div style={style.buttons}>
        <Button
          variant="contained"
          style={style.buttonDelete}
          onClick={() => handleDelete()}
        >
          Usuń wyprawę
        </Button>
        <Button
          variant="contained"
          style={style.buttonEdit}
          onClick={() => editTrip()}
        >
          Podgląd / Edycja wyprawy
        </Button>
      </div>
      <Dialog
        open={open}
        onClose={() => setOpen(false)}
      >
        <DialogTitle>Usuń wyprawę</DialogTitle>
        <IconButton
          aria-label="close"
          onClick={() => setOpen(false)}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500]
          }}
        >
          <Close/>
        </IconButton>
        <DialogContent sx={{ padding: '20px' }}>
          Pamiętaj, że jak usuniesz wyprawę, to nie będzie już dostępna. Czy na pewno chcesz ją usunąć ?
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            sx={{
              background: '#cd3838'
            }}
            onClick={() => deleteElement()}
          >
            Usuń wyprawę
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}

export default ListTrips
