import HeadingPage from '../../components/atoms/HeadingPage/HeadingPage'
import { useEffect, useState } from 'react'
import { getPostById } from '../../firebase'
import Typography from '@mui/material/Typography'
import { Box, Grid } from '@mui/material'
import DomainHelper from '../../helpers/DomainHelper/DomainHelper'
import favicon from '../../assets/configFiles/favicon.png'
import favicon2 from '../../assets/configFiles/favicon2.png'
import { Helmet } from 'react-helmet'

const PostPage = () => {
  const idPost = window.location.pathname.split('/')[2]
  const [data, setData] = useState(null)
  useEffect(() => {
    if (idPost?.length > 0) {
      getPostById(idPost).then((res) => res && setData(res))
    }
  }, [idPost])
  return (
    <>
      <Helmet>
        <meta charSet="utf-8"/>
        <title>
          {DomainHelper.isAp() ? 'ApCamper - wyprawy kamperowe, Wynajem kamperów Koszalin' : 'Adventurepeople - sport, przygoda i wyprawy'}
        </title>
        <link
          rel="icon"
          href={DomainHelper.isAp() ? favicon : favicon2}
        />
      </Helmet>
      <HeadingPage
        small="Przeczytaj więcej na ten temat"
        name={data?.name}
        background={data?.image}
      />
      <Box sx={{ padding: '50px' }}>
        <Grid container spacing={4}>
          <Grid item md={12} xs={12}>
            <Typography sx={{ marginBottom: '20px', fontSize: '20px' }}>
              Autor: Adrian Tarkowski
            </Typography>
            <Typography sx={{ fontSize: '25px' }} dangerouslySetInnerHTML={{ __html: data?.description }}>
            </Typography>
          </Grid>
        </Grid>
      </Box>
    </>
  )
}

export default PostPage
