import { Box, Button, Checkbox, Grid, Link, TextField } from '@mui/material'
import Typography from '@mui/material/Typography'
import { useEffect, useState } from 'react'
import regulation from '../../../assets/configFiles/regulation.pdf'
import agreement from '../../../assets/configFiles/agreement.pdf'
import { useSnackbar } from 'notistack'
import { createOrUpdateReservations } from '../../../firebase'
import DateHelper from '../../../helpers/DateHelper/DateHelper'
import DomainHelper from '../../../helpers/DomainHelper/DomainHelper'

const ShowCamper = (props) => {
  const idCamper = window.location.pathname.split('/')[2]
  const { data } = props
  const { enqueueSnackbar } = useSnackbar()
  const generateSezon = () => {
    const month = (new Date()).getMonth()
    let sezon = 'Poza sezonem'
    if (month === 4 || month === 5 || month === 8) {
      sezon = 'niski (maj, czerwiec, wrzesień)'
    } else if (month === 6 || month === 7) {
      sezon = 'wysoki (lipiec, sierpień)'
    }
    return sezon
  }
  const [people, setPeople] = useState(1)
  const [email, setEmail] = useState('')
  const [phone, setPhone] = useState('')
  const today = new Date()
  const todayYear = today.getFullYear()
  const todayMonth = (today.getMonth() + 1).toString().length > 1 ? today.getMonth() + 1 : '0' + (today.getMonth() + 1)
  const todayDay = (today.getDate()).toString().length > 1 ? today.getDate() : '0' + today.getDate()
  const tomorrowDay = (today.getDate() + 1).toString().length > 1 ? today.getDate() + 1 : '0' + (today.getDate() + 1)
  const [dateStart, setDateStart] = useState(`${todayYear}-${todayMonth}-${todayDay}`)
  const [dateEnd, setDateEnd] = useState(`${todayYear}-${todayMonth}-${tomorrowDay}`)
  const [animals, setAnimals] = useState(false)
  const [linen, setLinen] = useState(false)
  const [grill, setGrill] = useState(false)
  const [table, setTable] = useState(false)
  const generatePrice = () => {
    const getDaysArray = function (start, end) {
      let arr = []
      let dt
      let index = 0
      for (arr = [], dt = new Date(start); dt <= new Date(end); dt.setDate(dt.getDate() + 1)) {
        if (index !== 0) {
          arr.push(new Date(dt))
        }
        index++
      }
      return arr
    }
    const daysList = getDaysArray(new Date(dateStart), new Date(dateEnd))
    daysList?.map((v) => v.toISOString().slice(0, 10)).join('')
    let priceToPay = 0
    daysList?.forEach(el => {
      const dateMonth = el?.getMonth() + 1
      const priceToPayInThisMonth = whatMonthPrice(dateMonth)
      priceToPay += priceToPayInThisMonth
    })
    return priceToPay
  }
  const whatMonthPrice = (month) => {
    console.log(month)
    let price = 0
    if (month === 1 || month === 2 || month === 3 || month === 4 || month === 10 || month === 11 || month === 12) {
      price = data?.pricePerDay
    } else if (month === 5 || month === 6 || month === 9) {
      price = data?.pricePerDaySummerLow
    } else if (month === 7 || month === 8) {
      price = data?.pricePerDaySummerHeight
    }
    return price
  }
  const [days, setDays] = useState(whatMonthPrice((new Date()).getMonth()) === 600 ? 7 : 1)
  const handleSave = (e) => {
    e.preventDefault()
    const sendData = {
      email,
      phone,
      days,
      people,
      animals,
      linen,
      grill,
      table,
      idCamper,
      dateStart,
      dateEnd,
      price: (generatePrice() * days) + (animals ? data?.paymentForAnimals : 0) + (linen ? people * 50 : 0) + (grill ? 150 : 0) + (table ? 250 : 0)
    }
    createOrUpdateReservations(sendData).then((res) => {
      if (res) {
        enqueueSnackbar('Dodano rezerwacje, będziemy się kontaktować w celu jej akceptacji oraz dalszych kroków.', { variant: 'success' })
      }
    })
  }
  const style = {
    root: {
      padding: '50px',
      display: 'flex',
      width: 'calc(100% - 100px)',
      alignItems: 'center',
      maxWidth: '1400px',
      margin: '0 auto'
    },
    image: {
      display: 'flex',
      alignItems: 'center',
      width: '100%',
      margin: '0 auto'
    },
    name: {
      fontSize: '35px !important',
      fontWeight: '900'
    },
    nameColor: {
      fontSize: '35px',
      fontWeight: '900',
      color: '#D5F0FC',
      background: DomainHelper.isAp() ? '#324b54' : '#575152',
      borderRadius: '3px',
      padding: '5px 20px',
      textAlign: 'center',
      marginBottom: '20px',
      maxWidth: '50%'
    },
    desc: {
      fontSize: '25px'
    }
  }
  useEffect(() => {
    if (dateStart && dateEnd) {
      setDays(DateHelper.getDiffDate(dateEnd, dateStart))
    }
  }, [dateStart, dateEnd])
  return (
    <Box sx={style.root}>
      <Grid container spacing={4}>
        <Grid item md={6}>
          <Grid container spacing={2}>
            <Grid item md={12}>
              <Typography sx={style.name}>
                {data?.name}
              </Typography>
              <Typography sx={style.nameColor}>
                {(generatePrice()) + (animals ? data?.paymentForAnimals : 0) + (linen ? people * 50 : 0) + (grill ? 150 : 0) + (table ? 250 : 0)}.00&nbsp;zł&nbsp;brutto
              </Typography>
              <Box className="texts">
                <Typography sx={{ marginTop: '20px' }}><b>VIN:</b> {data?.vin}</Typography>
                <Typography><b>Aktualny sezon:</b> {generateSezon()}</Typography>
              </Box>
            </Grid>
            <Grid item md={6}>
              <form onSubmit={(e) => handleSave(e)}>
                <Box>
                  <TextField
                    sx={{ marginBottom: '20px' }}
                    fullWidth
                    label="Podaj email"
                    type="email"
                    value={email}
                    required
                    onChange={(e) => setEmail(e.target.value)}/>
                  <TextField
                    sx={{ marginBottom: '20px' }}
                    fullWidth
                    label="Podaj telefon"
                    type="tel"
                    value={phone}
                    required
                    onChange={(e) => setPhone(e.target.value)}/>
                  <TextField
                    sx={{ marginBottom: '20px' }}
                    fullWidth
                    label="Data rozpoczęcia"
                    type="date"
                    value={dateStart}
                    inputProps={{
                      min: `${todayYear}-${todayMonth}-${todayDay}`
                    }}
                    required
                    onChange={(e) => {
                      const date = e.target.value.split('-')
                      setDateStart(e.target.value)
                      const dateConvert = new Date(date[0], date[1], date[2])
                      dateConvert.setDate(dateConvert.getDate() + 1)
                      setDateEnd(`${dateConvert.getFullYear()}-${(dateConvert.getMonth() + 1).toString().length > 1 ? dateConvert.getMonth() + 1 : '0' + (dateConvert.getMonth() + 1)}-${(dateConvert.getDate()).toString().length > 1 ? dateConvert.getDate() : '0' + dateConvert.getDate()}`)
                    }}
                  />
                  <TextField
                    sx={{ marginBottom: '20px' }}
                    fullWidth
                    label="Data zakończenia"
                    type="date"
                    value={dateEnd}
                    inputProps={{
                      min: dateStart
                    }}
                    required
                    onChange={(e) => setDateEnd(e.target.value)}/>
                  <TextField
                    fullWidth
                    label="Ilość osób"
                    type="number"
                    min={1}
                    defaultValue={1}
                    value={people}
                    required
                    onChange={(e) => setPeople(e.target.value)}/>
                </Box>
                <Box sx={{ position: 'relative', left: '-10px', marginTop: '10px' }}>
                  <Checkbox
                    checked={animals}
                    onChange={() => setAnimals(!animals)}/>
                  <span>Czy bierzesz zwierzęta ?</span>
                </Box>
                <Typography sx={{ fontWeight: '600', marginTop: '20px' }}>
                  W cenie
                </Typography>
                <Typography>
                  • wykładzina przedsionkowa<br/>
                  • Podkłady poziomujące<br/>
                  • przedłużacz bębnowy<br/>
                  • Komplet sztućców<br/>
                  • komplet talerzy, misek, kubków<br/>
                  • garnki, patelnia<br/>
                  • szczotka z szufelką<br/>
                </Typography>
                <Typography sx={{ fontWeight: '600', marginTop: '20px' }}>
                  Usługi dodatkowe
                </Typography>
                <Box sx={{ position: 'relative', left: '-10px' }}>
                  <Checkbox
                    checked={linen}
                    onChange={() => setLinen(!linen)}/>
                  <span>Komplet pościeli, 50 zł od osoby</span>
                </Box>
                <Box sx={{ position: 'relative', left: '-10px' }}>
                  <Checkbox
                    checked={grill}
                    onChange={() => setGrill(!grill)}/>
                  <span>Grill gazowy, 150 zł</span>
                </Box>
                <Box sx={{ position: 'relative', left: '-10px' }}>
                  <Checkbox
                    checked={table}
                    onChange={() => setTable(!table)}/>
                  <span>Stolik + 5 krzeseł z oparciem, 250 zł</span>
                </Box>
                <Button
                  fullWidth
                  variant="contained"
                  sx={{
                    marginTop: '20px',
                    fontSize: '20px !important'
                  }}
                  type="submit"
                >
                  Zarezerwuj
                </Button>
              </form>
            </Grid>
            <Grid item md={6} className="texts">
              <Typography><b>Cena wyjściowa:</b><br/> {generatePrice()}.00 zł</Typography>
              {
                animals && (
                  <Typography><b>Opłata za zwierzęta:</b><br/> {data?.paymentForAnimals}.00 zł</Typography>
                )
              }
              {
                linen && (
                  <Typography><b>Opłata za pościel:</b><br/> {people * 50}.00 zł</Typography>
                )
              }
              {
                grill && (
                  <Typography><b>Opłata za grill gazowy:</b><br/> 150.00 zł</Typography>
                )
              }
              {
                table && (
                  <Typography><b>Opłata za stolik z krzesłami z oparciem:</b><br/> 250.00 zł</Typography>
                )
              }
            </Grid>
          </Grid>
        </Grid>
        <Grid item md={6}>
          <img src={data?.image} alt="image" style={style.image}/>
          {
            data?.iframe && (
              <Box dangerouslySetInnerHTML={{ __html: data?.iframe }} sx={{ width: '100%', marginTop: '30px' }}/>
            )
          }
        </Grid>
        {
          data?.image1 && (
            <Grid item md={12} className="texts">

              <Typography sx={{ fontWeight: '900', fontSize: '35px !important' }}>GALERIA ZDJĘĆ</Typography>
              {
                data && (
                  <Grid container spacing={4}>
                    {
                      [...Array(10)].map((el, index) => {
                        return (
                          <Grid key={index} item md={4}>
                            {data[`image${index + 1}`] &&
                              <img src={data[`image${index + 1}`]} alt={`image${index + 1}`} style={{ width: '100%' }}/>}
                          </Grid>
                        )
                      })
                    }
                  </Grid>
                )
              }
            </Grid>
          )
        }
        <Grid item md={12} className="texts">
          <Typography sx={{ fontWeight: '900', fontSize: '35px !important' }}>PODSTAWOWE WYMAGANIA</Typography>
          <Typography><b>Minimalny wiek osoby wynajmującej:</b> 25 lat</Typography>
          <Typography><b>Odbiór auta:</b> 15:00 - 16:00</Typography>
          <Typography><b>Zdanie auta:</b> do 10:00</Typography>
          <Typography><b>Regulamin wynajmu:</b> <Link target="_blank" href={regulation}>dostępny pod
            linkiem</Link></Typography>
          <Typography><b>Umowa wynajmu:</b> <Link target="_blank" href={agreement}>dostępna pod
            linkiem</Link></Typography>
          <Typography><b>Protokół zdawczo odbiorczy:</b> <Link target="_blank" href={'#'}>dostępna pod
            linkiem</Link></Typography>
        </Grid>
        <Grid item md={12} className="texts">
          <Typography sx={{ fontWeight: '900', fontSize: '35px !important' }}>DANE SEZONOWE</Typography>
          <Typography><b>Poza sezonem:</b> (styczeń, luty, marzec, kwiecień, październik, listopad,
            grudzień) - {data?.pricePerDay}.00 zł / dzień</Typography>
          <Typography><b>Sezon niski:</b> (maj, czerwiec, wrzesień) - {data?.pricePerDaySummerLow}.00 zł / dzień
          </Typography>
          <Typography><b>Sezon wysoki:</b> (lipiec, sierpień) - {data?.pricePerDaySummerHeight}.00 zł / dzień
          </Typography>
        </Grid>
        <Grid item md={12} className="texts">
          <Typography sx={{ fontWeight: '900', fontSize: '35px !important' }}>DODATKOWE OPŁATY</Typography>
          <Typography><b>Opłata serwisowa:</b> {data?.paymentForService}.00 zł</Typography>
          <Typography><b>Wysokość kaucji:</b> {data?.deposit}.00 zł</Typography>
          <Typography><b>Kara za palenie:</b> {data?.smokingPenalty}.00 zł</Typography>
          <Typography><b>Kara za niepodstawienie auta o danej godzinie:</b> {data?.notSubstitutingTheCar}.00 zł /
            h</Typography>
        </Grid>
        <Grid item md={12} className="texts">
          <Typography sx={{ fontWeight: '900', fontSize: '35px !important' }}>OPIS</Typography>
          <Typography sx={style.desc} dangerouslySetInnerHTML={{ __html: data?.description }}></Typography>
        </Grid>
        <Grid item md={12} className="texts">
          <Typography sx={{ fontWeight: '900', fontSize: '35px !important' }}>DANE TECHNICZNE POJAZDU</Typography>
          <Typography><b>Rok produkcji:</b> {data?.year}</Typography>
          <Typography><b>Pojemność silnika:</b> {data?.capacity} l</Typography>
          <Typography><b>Przebieg:</b> {data?.mileage} km</Typography>
          <Typography><b>Skrzynia biegów:</b> {data?.transmission}</Typography>
          <Typography><b>Miejsca do jazdy:</b> {data?.placesToRide}</Typography>
          <Typography><b>Miejsca do spania:</b> {data?.placesToSleep}</Typography>
          <Typography><b>Długość:</b> {(data?.length / 100)} m</Typography>
          <Typography><b>Ładowność:</b> {(data?.capacityCar)} kg</Typography>
          <Typography><b>Kolor:</b> {data?.color}</Typography>
        </Grid>
        <Grid item md={12} className="texts">
          <Typography sx={{ fontWeight: '900', fontSize: '35px !important' }}>DODATKOWE DANE</Typography>
          <Typography sx={style.desc} dangerouslySetInnerHTML={{ __html: data?.additionalTechnicalData }}></Typography>
        </Grid>
        <Grid item md={12} className="texts">
          <Typography sx={{ fontWeight: '900', fontSize: '35px !important' }}>AKCESORIA</Typography>
          <Typography sx={style.desc} dangerouslySetInnerHTML={{ __html: data?.accessories }}></Typography>
        </Grid>
      </Grid>
    </Box>
  )
}

export default ShowCamper
