import DomainHelper from './DomainHelper'

export default {
  whatDomain: () => {
    const domain = window?.location?.host?.replace('www.', '')
    if (domain === 'localhost:3000') {
      return 'local'
    } else {
      if (domain === 'apcamper.pl') {
        return 'ap'
      }
      if (domain === 'adventurepeople.eu' || domain === 'adventurepeople.pl') {
        return 'ad'
      }
    }
  },
  isAd: () => {
    return DomainHelper.whatDomain() === 'local' || DomainHelper.whatDomain() === 'ad'
  },
  isAp: () => {
    return DomainHelper.whatDomain() === 'local' || DomainHelper.whatDomain() === 'ap'
  }
}
