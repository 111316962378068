import BreakpointsHelper from '../../helpers/BreakpointsHelper/BreakpointsHelper'
import './AdminPage.scss'
import Reservation from '../../components/molecules/Reservation/Reservation'
import Typography from '@mui/material/Typography'
import { useEffect, useState } from 'react'
import { Box, Grid } from '@mui/material'
import Button from '@mui/material/Button'
import {
  createOrUpdateConfigCompany,
  deleteCamperById,
  deleteGalleryById,
  deletePostById,
  deleteReservationById,
  deleteReservationTripById,
  deleteTripById,
  getAllCampers,
  getAllGalleries,
  getAllPosts,
  getAllReservations,
  getAllReservationsTrips,
  getAllTrips,
  getDataFromPath
} from '../../firebase'
import ListPost from '../../components/molecules/ListPost/ListPost'
import ListCamper from '../../components/molecules/ListCamper/ListCamper'
import { useSnackbar } from 'notistack'
import FormGenerator from '../../components/organisms/FormGenerator/FormGenerator'
import GalleryPost from '../../components/molecules/GalleryPost/GalleryPost'
import ListTrips from '../../components/molecules/ListTrips/ListTrips'
import { useHistory } from 'react-router'
import ReservationTrip from '../../components/molecules/ReservationTrip/ReservationTrip'
import DomainHelper from '../../helpers/DomainHelper/DomainHelper'
import favicon from '../../assets/configFiles/favicon.png'
import favicon2 from '../../assets/configFiles/favicon2.png'
import { Helmet } from 'react-helmet'

const AdminPage = (props) => {
  const history = useHistory()
  const notificationType = props?.location?.location?.search?.replace('?', '').split('=')[0]
  const style = {
    root: {
      width: BreakpointsHelper.isMobile() ? 'calc(100% - 30px)' : 'calc(100% - 110px)',
      padding: BreakpointsHelper.isMobile() ? '15px' : '20px 50px'
    }
  }
  const { enqueueSnackbar } = useSnackbar()
  const [data, setData] = useState(null)
  const [dataTrips, setDataTrips] = useState(null)
  const [posts, setPosts] = useState(null)
  const [trips, setTrips] = useState(null)
  const [galleries, setGalleries] = useState(null)
  const [campers, setCampers] = useState(null)
  const [formData, setFormData] = useState(null)
  const addCamper = () => props.location.history.push('/addCamper')
  const addTrip = () => props.location.history.push('/addTrip')
  const addPost = () => props.location.history.push('/addPost')
  const getData = () => getAllReservations().then((res) => res && setData(res))
  const getDataTrips = () => getAllReservationsTrips().then((res) => res && setDataTrips(res))
  const getCampers = () => getAllCampers().then((res) => res && setCampers(res))
  const getTrips = () => getAllTrips().then((res) => res && setTrips(res))
  const getPosts = () => getAllPosts().then((res) => res && setPosts(res))
  const getGalleries = () => getAllGalleries().then((res) => res && setGalleries(res))
  const addGallery = () => props.location.history.push('/addGallery')
  const deletePost = (id) => deletePostById(id).then(() => window.open('/admin?deletePost=true', '_self'))
  const deleteTrip = (id) => deleteTripById(id).then(() => window.open('/admin?deleteTrip=true', '_self'))
  const deleteGallery = (id) => deleteGalleryById(id).then(() => window.open('/admin?deleteGallery=true', '_self'))
  const deleteReservation = (id) => deleteReservationById(id).then(() => window.open('/admin?deleteReservation=true', '_self'))
  const deleteReservationTrip = (id) => deleteReservationTripById(id).then(() => window.open('/admin?deleteReservation=true', '_self'))
  const deleteCamper = (id) => deleteCamperById(id).then(() => window.open('/admin?deleteCamper=true', '_self'))
  const handleBanner = (e) => {
    createOrUpdateConfigCompany(e).then((res) => {
      if (res) {
        enqueueSnackbar('Poprawnie zmodyfikowano konfiguracje baneru.', { variant: 'success' })
      }
    })
  }
  const getBannerData = () => {
    getDataFromPath('config', 'apcamper').then((res) => {
      if (res) {
        const tempData = {
          elements: [
            {
              name: 'textUp',
              label: 'Tekst powyżej',
              value: res?.textUp || '',
              validationType: 'string'
            },
            {
              name: 'textCenter',
              label: 'Tekst pośrodku',
              value: res?.textCenter || '',
              validationType: 'string'
            },
            {
              name: 'textBottom',
              label: 'Tekst poniżej',
              value: res?.textBottom || '',
              validationType: 'string'
            },
            {
              name: 'textButton',
              label: 'Tekst na przycisku',
              value: res?.textButton || '',
              validationType: 'string'
            },
            {
              name: 'buttonLink',
              label: 'Adres przekierowania przycisku',
              value: res?.buttonLink || '',
              validationType: 'string'
            },
            {
              name: 'textUp2',
              label: 'Tekst powyżej 2',
              value: res?.textUp2 || '',
              validationType: 'string'
            },
            {
              name: 'textCenter2',
              label: 'Tekst pośrodku 2',
              value: res?.textCenter2 || '',
              validationType: 'string'
            },
            {
              name: 'textBottom2',
              label: 'Tekst poniżej 2',
              value: res?.textBottom2 || '',
              validationType: 'string'
            },
            {
              name: 'textButton2',
              label: 'Tekst na przycisku 2',
              value: res?.textButton2 || '',
              validationType: 'string'
            },
            {
              name: 'buttonLink2',
              label: 'Adres przekierowania przycisku 2',
              value: res?.buttonLink2 || '',
              validationType: 'string'
            },
            {
              type: 'button',
              value: 'Zapisz konfigurację'
            }
          ]
        }
        setFormData(tempData)
      }
    })
  }
  useEffect(() => {
    getData()
    getDataTrips()
    getPosts()
    getGalleries()
    getCampers()
    getTrips()
    getBannerData()
  }, [])
  useEffect(() => {
    if (notificationType) {
      switch (notificationType) {
        case 'deleteCamper':
          enqueueSnackbar('Poprawnie usunięto kampera.', { variant: 'success' })
          break
        case 'updateCamper':
          enqueueSnackbar('Poprawnie zmieniono kampera.', { variant: 'success' })
          break
        case 'updateTrip':
          enqueueSnackbar('Poprawnie zmieniono wyprawę.', { variant: 'success' })
          break
        case 'createCamper':
          enqueueSnackbar('Poprawnie utworzono kampera.', { variant: 'success' })
          break
        case 'createTrip':
          enqueueSnackbar('Poprawnie utworzono wyprawę.', { variant: 'success' })
          break
        case 'deletePost':
          enqueueSnackbar('Poprawnie usunięto posta.', { variant: 'success' })
          break
        case 'deleteGallery':
          enqueueSnackbar('Poprawnie usunięto galerię.', { variant: 'success' })
          break
        case 'deleteTrip':
          enqueueSnackbar('Poprawnie usunięto wyprawę.', { variant: 'success' })
          break
        case 'updatePost':
          enqueueSnackbar('Poprawnie zmieniono posta.', { variant: 'success' })
          break
        case 'updateReservation':
          enqueueSnackbar('Poprawnie zmieniono rezerwacje.', { variant: 'success' })
          break
        case 'addPost':
          enqueueSnackbar('Poprawnie utworzono posta.', { variant: 'success' })
          break
        case 'addGallery':
          enqueueSnackbar('Poprawnie utworzono galerię.', { variant: 'success' })
          break
        case 'updateGallery':
          enqueueSnackbar('Poprawnie zmieniono galerię.', { variant: 'success' })
          break
        case 'deleteImageFromGallery':
          enqueueSnackbar('Poprawnie usunięto zdjęcie z galerii.', { variant: 'success' })
          break
      }
    }
  }, [notificationType])
  return (
    <>
      <Helmet>
        <meta charSet="utf-8"/>
        <title>
          {DomainHelper.isAp() ? 'ApCamper - wyprawy kamperowe, Wynajem kamperów Koszalin' : 'Adventurepeople - sport, przygoda i wyprawy'}
        </title>
        <link
          rel="icon"
          href={DomainHelper.isAp() ? favicon : favicon2}
        />
      </Helmet>
      <div style={style.root}>
        <Grid container spacing={4}>
          <Grid item md={12} xs={12}>
            <Typography
              sx={{
                fontWeight: '400',
                fontSize: '30px',
                marginBottom: '20px'
              }}
            >
              Lista rezerwacji
            </Typography>
          </Grid>
        </Grid>
        {
          data?.length > 0
            ? data?.map((el, index) => (
              <Reservation
                key={index}
                onDelete={(e) => deleteReservation(e)}
                data={el}
                allData={data}
                {...props}
              />
            ))
            : (
              <Typography sx={{ marginBottom: '20px' }}>
                Brak dostępnych rezerwacji
              </Typography>
            )
        }
        <Grid container spacing={4}>
          <Grid item md={12} xs={12}>
            <Typography
              sx={{
                fontWeight: '400',
                fontSize: '30px',
                marginBottom: '20px'
              }}
            >
              Lista rezerwacji wypraw
            </Typography>
          </Grid>
        </Grid>
        {
          dataTrips?.length > 0
            ? dataTrips?.map((el, index) => (
              <ReservationTrip
                key={index}
                onDelete={(e) => deleteReservationTrip(e)}
                data={el}
                allData={dataTrips}
                {...props}
              />
            ))
            : (
              <Typography sx={{ marginBottom: '20px' }}>
                Brak dostępnych rezerwacji wypraw
              </Typography>
            )
        }
        <Grid container spacing={4}>
          <Grid item md={6} xs={12}>
            <Typography
              sx={{
                fontWeight: '400',
                fontSize: '30px',
                marginBottom: '20px'
              }}
            >
              Lista kamperów
            </Typography>
          </Grid>
          <Grid item md={6} xs={12}>
            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
              <Button
                variant="contained"
                onClick={() => addCamper()}
              >
                Dodaj kampera
              </Button>
            </Box>
          </Grid>
        </Grid>
        {
          campers?.length > 0
            ? campers?.map((el, index) => (
              <ListCamper
                key={index}
                onDelete={(e) => deleteCamper(e)}
                data={el}
              />
            ))
            : (
              <Typography
                sx={{
                  marginBottom: '20px'
                }}
              >
                Brak dostępnych kamperów
              </Typography>
            )
        }
        <Grid container spacing={4}>
          <Grid item md={6} xs={12}>
            <Typography
              sx={{
                fontWeight: '400',
                fontSize: '30px',
                marginBottom: '20px'
              }}
            >
              Lista wypraw
            </Typography>
          </Grid>
          <Grid item md={6} xs={12}>
            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
              <Button
                variant="contained"
                onClick={() => addTrip()}
              >
                Dodaj wyprawę
              </Button>
            </Box>
          </Grid>
        </Grid>
        {
          trips?.length > 0
            ? trips?.map((el, index) => (
              <ListTrips
                key={index}
                onDelete={(e) => deleteTrip(e)}
                data={el}
              />
            ))
            : (
              <Typography
                sx={{
                  marginBottom: '20px'
                }}
              >
                Brak dostępnych wypraw
              </Typography>
            )
        }
        <Grid container spacing={4}>
          <Grid item md={6} xs={12}>
            <Typography
              sx={{
                fontWeight: '400',
                fontSize: '30px',
                marginBottom: '20px'
              }}
            >
              Lista postów
            </Typography>
          </Grid>
          <Grid item md={6} xs={12}>
            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
              <Button
                variant="contained"
                onClick={() => addPost()}
              >
                Dodaj posta
              </Button>
            </Box>
          </Grid>
        </Grid>
        {
          posts?.length > 0
            ? posts?.map((el, index) => (
              <ListPost
                key={index}
                onDelete={(e) => deletePost(e)}
                data={el}
                {...props}
              />
            ))
            : (
              <Typography
                sx={{
                  marginBottom: '20px'
                }}
              >
                Brak dostępnych postów
              </Typography>
            )
        }
        <Grid container spacing={4}>
          <Grid item md={6} xs={12}>
            <Typography
              sx={{
                fontWeight: '400',
                fontSize: '30px',
                marginBottom: '20px'
              }}
            >
              Lista galerii
            </Typography>
          </Grid>
          <Grid item md={6} xs={12}>
            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
              <Button
                variant="contained"
                onClick={() => addGallery()}
              >
                Dodaj galerię
              </Button>
            </Box>
          </Grid>
        </Grid>
        {
          galleries?.length > 0
            ? galleries?.map((el, index) => (
              <GalleryPost
                key={index}
                onDelete={(e) => deleteGallery(e)}
                data={el}
                {...props}
              />
            ))
            : (
              <Typography
                sx={{
                  marginBottom: '20px'
                }}
              >
                Brak dostępnych galerii
              </Typography>
            )
        }
        {
          formData?.elements?.length > 0 && (
            <Grid container spacing={4}>
              <Grid item md={6} xs={12}>
                <Typography
                  sx={{
                    fontWeight: '400',
                    fontSize: '30px',
                    marginBottom: '20px'
                  }}
                >
                  Konfiguracja baneru
                </Typography>
              </Grid>
              <Grid item md={12}>
                <FormGenerator data={formData} submit={(e) => handleBanner(e)}/>
              </Grid>
            </Grid>
          )
        }
      </div>
    </>
  )
}

export default AdminPage
