import { useEffect, useState } from 'react'
import { getAllTrips } from '../../firebase'
import HeadingPage from '../../components/atoms/HeadingPage/HeadingPage'
import ShowTrip from '../../components/atoms/ShowTrip/ShowTrip'
import DomainHelper from '../../helpers/DomainHelper/DomainHelper'
import favicon from '../../assets/configFiles/favicon.png'
import favicon2 from '../../assets/configFiles/favicon2.png'
import { Helmet } from 'react-helmet'

const TripPage = () => {
  const idTrip = window.location.pathname.split('/')[2]
  const [trip, setTrip] = useState(null)
  useEffect(() => {
    getAllTrips().then((res) => {
      if (res) {
        const temp = []
        res.forEach(el => {
          if (el.id === idTrip) {
            temp.push(el)
          }
        })
        setTrip(temp[0])
      }
    })
  }, [])
  return (
    <>
      <Helmet>
        <meta charSet="utf-8"/>
        <title>
          {DomainHelper.isAp() ? 'ApCamper - wyprawy kamperowe, Wynajem kamperów Koszalin' : 'Adventurepeople - sport, przygoda i wyprawy'}
        </title>
        <link
          rel="icon"
          href={DomainHelper.isAp() ? favicon : favicon2}
        />
      </Helmet>
      <HeadingPage
        small="Wybierz wydarzenie dla siebie"
        name="Wydarzenie u nas to dosłownie parę kroków"
      />
      <ShowTrip data={trip}/>
    </>
  )
}

export default TripPage
