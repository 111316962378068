import BreakpointsHelper from '../../helpers/BreakpointsHelper/BreakpointsHelper'
import FormGenerator from '../../components/organisms/FormGenerator/FormGenerator'
import { useEffect, useState } from 'react'
import { createOrUpdateTrip, getTripById } from '../../firebase'
import { useHistory } from 'react-router'
import DomainHelper from '../../helpers/DomainHelper/DomainHelper'
import favicon from '../../assets/configFiles/favicon.png'
import favicon2 from '../../assets/configFiles/favicon2.png'
import { Helmet } from 'react-helmet'

const EditTripPage = () => {
  const idTrip = window.location.pathname.split('/')[2]
  const history = useHistory()
  const [formData, setFormData] = useState(null)
  const [data, setData] = useState(null)
  const style = {
    root: {
      width: BreakpointsHelper.isMobile() ? 'calc(100% - 30px)' : 'calc(100% - 110px)',
      padding: BreakpointsHelper.isMobile() ? '15px' : '20px 50px'
    }
  }
  const getFormData = () => {
    const tempData = {
      elements: [
        {
          name: 'name',
          type: 'text',
          value: data?.name,
          label: 'Nazwa wyprawy'
        },
        {
          name: 'type',
          type: 'multiSelect',
          label: 'Rodzaj wyprawy',
          defaultValue: ['Wyprawy zagraniczne', 'Wyprawy sportowe'],
          items: [
            { name: 'Wyprawy zagraniczne', value: 'Wyprawy zagraniczne' },
            { name: 'Wyprawy w Polsce', value: 'Wyprawy w Polsce' },
            { name: 'Wyprawy sportowe', value: 'Wyprawy sportowe' },
            { name: 'Wyprawy kamperem', value: 'Wyprawy kamperem' }
          ]
        },
        {
          name: 'startDate',
          type: 'date',
          value: data?.startDate,
          label: 'Data rozpoczęcia'
        },
        {
          name: 'endDate',
          type: 'date',
          value: data?.endDate,
          label: 'Data zakończenia'
        },
        {
          name: 'forWho',
          type: 'text',
          value: data?.forWho,
          label: 'Dla kogo?'
        },
        {
          name: 'schedule',
          type: 'editor',
          value: data?.schedule,
          label: 'Harmonogram'
        },
        {
          name: 'transport',
          type: 'editor',
          value: data?.transport,
          label: 'Transport'
        },
        {
          name: 'adds',
          type: 'editor',
          value: data?.adds,
          label: 'Ważne informacje'
        },
        {
          name: 'price',
          type: 'number',
          value: data?.price,
          label: 'Cena w brutto (zł)'
        },
        {
          name: 'priceExtended',
          type: 'editor',
          value: data?.priceExtended,
          label: 'Cena zawiera'
        },
        {
          name: 'priceNotExtended',
          type: 'editor',
          value: data?.priceNotExtended,
          label: 'Cena nie zawiera'
        },
        {
          name: 'payment',
          type: 'editor',
          value: data?.payment,
          label: 'Rodzaje płatności'
        },
        {
          name: 'schedule',
          type: 'editor',
          value: data?.schedule,
          label: 'Warunki rezygnacji'
        },
        {
          name: 'contact',
          type: 'editor',
          value: data?.contact,
          label: 'Kontakt'
        },
        {
          name: 'image',
          type: 'text',
          value: data?.image,
          label: 'Zdjęcie domyślne'
        },
        {
          name: 'image1',
          type: 'text',
          value: data?.image1,
          label: 'Zdjęcie do galerii 1'
        },
        {
          name: 'image2',
          type: 'text',
          value: data?.image2,
          label: 'Zdjęcie do galerii 2'
        },
        {
          name: 'image3',
          type: 'text',
          value: data?.image3,
          label: 'Zdjęcie do galerii 3'
        },
        {
          name: 'image4',
          type: 'text',
          value: data?.image4,
          label: 'Zdjęcie do galerii 4'
        },
        {
          name: 'image5',
          type: 'text',
          value: data?.image5,
          label: 'Zdjęcie do galerii 5'
        },
        {
          name: 'image6',
          type: 'text',
          value: data?.image6,
          label: 'Zdjęcie do galerii 6'
        },
        {
          name: 'image7',
          type: 'text',
          value: data?.image7,
          label: 'Zdjęcie do galerii 7'
        },
        {
          name: 'image8',
          type: 'text',
          value: data?.image8,
          label: 'Zdjęcie do galerii 8'
        },
        {
          name: 'image9',
          type: 'text',
          value: data?.image9,
          label: 'Zdjęcie do galerii 9'
        },
        {
          name: 'image10',
          type: 'text',
          value: data?.image10,
          label: 'Zdjęcie do galerii 10'
        },
        {
          type: 'button',
          value: 'Zapisz wyprawę'
        }
      ]
    }
    setFormData(tempData)
  }
  const updateTrip = (e) => {
    createOrUpdateTrip(e, idTrip).then((res) => {
      if (res) history.push('/admin?updateTrip=true')
    })
  }
  useEffect(() => {
    if (idTrip?.length > 0) {
      getTripById(idTrip).then((res) => res && setData(res))
    }
  }, [idTrip])
  useEffect(() => {
    if (data) {
      getFormData()
    }
  }, [data])
  return (
    <>
      <Helmet>
        <meta charSet="utf-8"/>
        <title>
          {DomainHelper.isAp() ? 'ApCamper - wyprawy kamperowe, Wynajem kamperów Koszalin' : 'Adventurepeople - sport, przygoda i wyprawy'}
        </title>
        <link
          rel="icon"
          href={DomainHelper.isAp() ? favicon : favicon2}
        />
      </Helmet>
      <div style={style.root}>
        {formData && data && <FormGenerator data={formData} submit={(e) => updateTrip(e)}/>}
      </div>
    </>
  )
}

export default EditTripPage
