import HeadingPage from '../../components/atoms/HeadingPage/HeadingPage'
import Contacts from '../../components/molecules/Contacts/Contacts'
import DomainHelper from '../../helpers/DomainHelper/DomainHelper'
import favicon from '../../assets/configFiles/favicon.png'
import favicon2 from '../../assets/configFiles/favicon2.png'
import { Helmet } from 'react-helmet'

const ContactPage = () => {
  return (
    <>
      <Helmet>
        <meta charSet="utf-8"/>
        <title>
          {DomainHelper.isAp() ? 'ApCamper - wyprawy kamperowe, Wynajem kamperów Koszalin' : 'Adventurepeople - sport, przygoda i wyprawy'}
        </title>
        <link
          rel="icon"
          href={DomainHelper.isAp() ? favicon : favicon2}
        />
      </Helmet>
      <HeadingPage
        small="Jesteśmy dla ciebie dostępni"
        name="Masz pytania? Napisz do nas lub zadzwoń, a pomożemy"
      />
      <Contacts/>
    </>
  )
}

export default ContactPage
