import BreakpointsHelper from '../../helpers/BreakpointsHelper/BreakpointsHelper'
import FormGenerator from '../../components/organisms/FormGenerator/FormGenerator'
import { useEffect, useState } from 'react'
import { createOrUpdatePosts } from '../../firebase'
import { useHistory } from 'react-router'
import DomainHelper from '../../helpers/DomainHelper/DomainHelper'
import favicon from '../../assets/configFiles/favicon.png'
import favicon2 from '../../assets/configFiles/favicon2.png'
import { Helmet } from 'react-helmet'

const AddPostPage = () => {
  const history = useHistory()
  const style = {
    root: {
      width: BreakpointsHelper.isMobile() ? 'calc(100% - 30px)' : 'calc(100% - 110px)',
      padding: BreakpointsHelper.isMobile() ? '15px' : '20px 50px'
    }
  }
  const [formData, setFormData] = useState(null)
  const getFormData = () => {
    const tempData = {
      elements: [
        {
          name: 'name',
          type: 'text',
          label: 'Nazwa posta'
        },
        {
          name: 'image',
          type: 'text',
          label: 'Zdjęcie'
        },
        {
          name: 'description',
          type: 'editor',
          label: 'Opis'
        },
        {
          name: 'type',
          type: 'select',
          items: [{ name: 'Wyprawy', value: 'Wyprawy' }, { name: 'Kampery', value: 'Kampery' }],
          label: 'Typ'
        },
        {
          type: 'button',
          value: 'Zapisz posta'
        }
      ]
    }
    setFormData(tempData)
  }
  const createPost = (e) => {
    createOrUpdatePosts(e).then((res) => {
      if (res) history.push('/admin?addPost=true')
    })
  }
  useEffect(() => {
    getFormData()
  }, [])
  return (
    <>
      <Helmet>
        <meta charSet="utf-8"/>
        <title>
          {DomainHelper.isAp() ? 'ApCamper - wyprawy kamperowe, Wynajem kamperów Koszalin' : 'Adventurepeople - sport, przygoda i wyprawy'}
        </title>
        <link
          rel="icon"
          href={DomainHelper.isAp() ? favicon : favicon2}
        />
      </Helmet>
      <div style={style.root}>
        {formData && <FormGenerator data={formData} submit={(e) => createPost(e)}/>}
      </div>
    </>
  )
}

export default AddPostPage
