import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import BreakpointsHelper from '../../helpers/BreakpointsHelper/BreakpointsHelper'
import { Helmet } from 'react-helmet'
import LocalStorageHelper from '../../helpers/LocalStorageHelper/LocalStorageHelper'
import Button from '@mui/material/Button'
import favicon from '../../assets/configFiles/favicon.png'
import favicon2 from '../../assets/configFiles/favicon2.png'
import Campers from '../../components/molecules/Campers/Campers'
import { useEffect, useState } from 'react'
import { getAllPosts } from '../../firebase'
import { Grid, TextField } from '@mui/material'
import camper from '../../assets/images/e8811ba0c60b32e9f6f4207a12499e0a.jpg'
import trip from '../../assets/images/asfafgassagsg.jpg'
import PostsList from '../../components/atoms/PostsList/PostsList'
import Heading from '../../components/atoms/Heading/Heading'
import Offers from '../../components/molecules/Offers/Offers'
import DomainHelper from '../../helpers/DomainHelper/DomainHelper'
import logo from '../../assets/configFiles/logo.png'
import logo2 from '../../assets/configFiles/logov2.png'
import Slider from '../../components/atoms/Slider/Slider'

const HomePage = (props) => {
  const t = LocalStorageHelper.get('translation')
  const [search, setSearch] = useState(null)
  const [defaultData, setDefaultData] = useState(null)
  const handleChange = (value) => {
    if (value?.length >= 3) {
      const tempData = []
      defaultData?.forEach(el => {
        if (el?.name.toLowerCase()?.search(value.toLowerCase()) >= 0) {
          tempData.push(el)
        }
      })
      setSearch(tempData)
    } else {
      setSearch(defaultData)
    }
  }
  const style = {
    blog: {
      width: 'calc(100% - 100px)',
      padding: '100px 50px'
    },
    search: {
      width: '100%',
      marginTop: '10px'
    }
  }
  useEffect(() => {
    getAllPosts().then(res => {
      if (res) {
        const temp = []
        if (DomainHelper.isAp()) {
          res?.forEach(el => {
            if (el?.type === 'Kampery') {
              temp.push(el)
            }
          })
        }
        if (DomainHelper.isAd()) {
          res?.forEach(el => {
            if (el?.type === 'Wyprawy') {
              temp.push(el)
            }
          })
        }
        setSearch(temp)
        setDefaultData(temp)
      }
    })
  }, [])
  return (
    <>
      <Helmet>
        <meta charSet="utf-8"/>
        <title>
          {DomainHelper.isAp() ? 'ApCamper - wyprawy kamperowe, Wynajem kamperów Koszalin' : 'Adventurepeople - sport, przygoda i wyprawy'}
        </title>
        <link
          rel="icon"
          href={DomainHelper.isAp() ? favicon : favicon2}
        />
        <meta
          name="keywords"
          content={DomainHelper.isAp() ? 'wynajem kamperów, kampery Koszalin, wyprawy kamperowe' : 'wynajem kamperów, sport, przygoda'}
        />
        <meta
          name="description"
          content={DomainHelper.isAp() ? 'Wynajem kamperów, kampery Koszalin, wyprawy kamperowe - ApCamper to komfortowe i w pełni wyposażone pojazdy, idealne na wyjazd z rodziną lub przyjaciółmi. Odkryj Polskę z ApCamper!' : 'Wynajem kamperów, kampery Koszalin, wyprawy - AdventurePeople to komfortowe i w pełni wyposażone pojazdy, idealne na wyjazd z rodziną lub przyjaciółmi. Odkryj Polskę i zagranicę z AdventurePeople!'}
        />
        <meta
          name="subject"
          content={DomainHelper.isAp() ? 'ApCamper - wyprawy kamperowe, Wynajem kamperów Koszalin' : 'Adventurepeople - sport, przygoda i wyprawy'}
        />
        <meta
          name="copyright"
          content={DomainHelper.isAp() ? 'ApCamper' : 'AdventurePeople'}
        />
        <meta name="language" content="PL"/>
        <meta name="robots" content="index,follow"/>
        <meta name="author" content="Osacorp, kontakt@osacorpsoft.pl"/>
        <meta name="designer" content="Osacorp, kontakt@osacorpsoft.pl"/>
        <meta name="copyright" content="ApCamper, rent@apcamper.pl"/>
        <meta name="owner" content="OSACORP"/>
        <meta
          name="url"
          content={DomainHelper.isAp() ? 'https://apcamper.pl' : 'https://adventurepeople.eu'}
        />
        <meta
          name="identifier-URL"
          content={DomainHelper.isAp() ? 'https://apcamper.pl' : 'https://adventurepeople.eu'}
        />
        <meta
          name="category"
          content={DomainHelper.isAp() ? 'Kampery' : 'Wyprawy'}
        />
        <meta name="distribution" content="Global"/>
        <meta name="rating" content="General"/>
        <meta name="revisit-after" content="7 days"/>
        <meta httpEquiv="Expires" content="0"/>
        <meta httpEquiv="Pragma" content="no-cache"/>
        <meta httpEquiv="Cache-Control" content="no-cache"/>
        <meta
          name="og:title"
          content={DomainHelper.isAp() ? 'ApCamper - wyprawy kamperowe, Wynajem kamperów Koszalin' : 'Adventurepeople - sport, przygoda i wyprawy'}
        />
        <meta name="og:type" content="website"/>
        <meta
          name="og:url"
          content={DomainHelper.isAp() ? 'https://apcamper.pl' : 'https://adventurepeople.eu'}
        />
        <meta
          name="og:site_name"
          content={DomainHelper.isAp() ? 'ApCamper' : 'AdventurePeople'}
        />
        <meta
          name="og:description"
          content={DomainHelper.isAp() ? 'Wynajem kamperów, kampery Koszalin, wyprawy kamperowe - ApCamper to komfortowe i w pełni wyposażone pojazdy, idealne na wyjazd z rodziną lub przyjaciółmi. Odkryj Polskę z ApCamper!' : 'Wynajem kamperów, kampery Koszalin, wyprawy - AdventurePeople to komfortowe i w pełni wyposażone pojazdy, idealne na wyjazd z rodziną lub przyjaciółmi. Odkryj Polskę i zagranicę z AdventurePeople!'}
        />
        <meta name="fb:page_id" content="apcamperpolska"/>
        <meta name="og:email" content="rent@apcamer.pl"/>
        <meta name="og:phone_number" content=""/>
        <meta name="og:locality" content="Koszalin"/>
        <meta name="og:region" content="Zachodniopomorskie"/>
        <meta name="og:postal-code" content=""/>
        <meta name="og:country-name" content="Polska"/>
        <meta property="og:type" content="website"/>
        <meta name="apple-mobile-web-app-capable" content="yes"/>
        <meta content="yes" name="apple-touch-fullscreen"/>
        <meta name="apple-mobile-web-app-status-bar-style" content="black"/>
        <meta name="format-detection" content="telephone=no"/>
      </Helmet>
      <Slider/>
      {
        DomainHelper.isAp() && (
          <Box
            sx={{
              margin: '100px 0',
              width: BreakpointsHelper.isMobile() ? 'calc(100% - 60px)' : 'calc(100% - 100px)',
              padding: BreakpointsHelper.isMobile() ? '30apx' : '20px 50px'
            }}
          >
            <Heading
              small="Poznaj naszą historię"
              name="Parę słów o nas"
            />
            <Grid container spacing={4}>
              <Grid md={6}>

                <Typography
                  sx={{ maxWidth: BreakpointsHelper.isMobile() ? '100%' : '90%', fontSize: '25px', paddingLeft: '40px' }}>
                  ApCamper to firma specjalizująca się w wynajmowaniu kamperów w Koszalinie i okolicach. Firma oferuje
                  szeroki wybór kamperów różnych klas i rozmiarów, które są w pełni wyposażone i gotowe do podróży.
                  Dzięki
                  temu, klienci firmy mogą cieszyć się swobodą podróżowania bez konieczności posiadania własnego
                  samochodu
                  kempingowego.
                  <br/><br/>
                  Firma ApCamper oferuje swoje usługi zarówno klientom indywidualnym, jak i firmowym. Dla klientów
                  indywidualnych wynajem kampera może być doskonałą opcją na wakacje lub weekendowy wypad poza miasto.
                  Dla
                  firm natomiast, wynajem kampera może być idealnym rozwiązaniem na potrzeby
                  organizacji wyjazdów integracyjnych.
                </Typography>
                <Button
                  sx={{
                    marginTop: '20px',
                    marginLeft: '40px',
                    marginBottom: '40px',
                    width: '320px'
                  }}
                  variant="contained"
                  onClick={() => props.location.history.push('/about')}
                >
                  Przeczytaj więcej
                </Button>
              </Grid>
              <Grid md={6}>
                <img
                  src={DomainHelper.isAp() ? camper : trip}
                  alt="camper"
                  style={{
                    width: '100%',
                    maxWidth: BreakpointsHelper.isMobile() ? '100%' : '1200px',
                    maxHeight: BreakpointsHelper.isMobile() ? 'auto' : '716px',
                    height: 'auto',
                    marginBottom: '20px'
                  }}
                />
              </Grid>
            </Grid>
          </Box>
        )
      }
      {
        DomainHelper.isAp() &&
        <>
          <Heading
            small="Wszystko to twój wybór"
            name="Wybierz dla siebie kampera"
          />
          <Campers/>
        </>
      }
      {
        DomainHelper.isAd() && (
          <>
            <Heading
              small="Twój cel"
              name="Twoje wymarzone miejsce docelowe"
            />
            <Offers/>
          </>
        )
      }
      <Box className="post" sx={style.blog}>
        <Heading
          small="Rozpocznij przygodę i zwiedź świat"
          name="Wyrusz w świat z Nami"
        />
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <TextField
              onKeyDown={(e) => handleChange(e.target.value)}
              label="Znajdź temat, który cię interesuje"
              sx={style.search}
            />
          </Grid>
          <Grid item xs={12}>
            <PostsList data={search}/>
          </Grid>
        </Grid>
      </Box>
      <Box className="post" sx={style.blog}>
        <Heading
          small="Sprawdź co jeszcze mamy w ofercie"
          name="Inne nasze działalności"
        />
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            flexDirection: BreakpointsHelper.isMobile() ? 'column' : 'rows'
          }}
        >
          {
            DomainHelper.isAd() && (
              <Box sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                height: '100%',
                width: BreakpointsHelper.isMobile() ? '100%' : 'auto'
              }}>
                <img
                  src={logo}
                  alt="logo"
                  style={{ cursor: 'pointer', maxHeight: '40px', marginTop: '12px', maxWidth: '280px' }}
                  onClick={() => window.open('https://apcamper.pl', '_blank')}
                />
              </Box>
            )
          }
          {
            DomainHelper.isAp() && (
              <Box sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                height: '100%',
                width: BreakpointsHelper.isMobile() ? '100%' : 'auto'
              }}>
                <img
                  src={logo2}
                  alt="logo"
                  style={{ cursor: 'pointer', maxHeight: '70px', maxWidth: '280px' }}
                  onClick={() => window.open('https://adventurepeople.eu', '_blank')}
                />
              </Box>
            )
          }
        </Box>
      </Box>
      <Box
        sx={{
          display: 'inline-flex',
          alignItems: BreakpointsHelper.isMobile() ? 'flex-start' : 'center',
          justifyContent: 'center',
          flexDirection: BreakpointsHelper.isMobile() && 'column',
          height: '80px',
          padding: '20px'
        }}
      >
        <Typography
          sx={{
            marginRight: '10px',
            marginLeft: '10px',
            cursor: 'pointer'
          }}
        >
          @ {new Date().getFullYear()} wszelkie prawa zastrzeżone przez APCAMPER. Strona stworzona przez &nbsp;
          <a href="/" target="_blank" rel="noreferrer">OSACORP</a>.
          Wszelkie informacje dostępne pod: <a href="#" target="_blank" rel="noreferrer">{t?.homePage?.footer.desc2}</a>
        </Typography>
      </Box>
    </>
  )
}

export default HomePage
