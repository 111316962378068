import HeadingPage from '../../components/atoms/HeadingPage/HeadingPage'
import { useEffect, useState } from 'react'
import { getAllCampers } from '../../firebase'
import ShowCamper from '../../components/atoms/ShowCamper/ShowCamper'
import DomainHelper from '../../helpers/DomainHelper/DomainHelper'
import favicon from '../../assets/configFiles/favicon.png'
import favicon2 from '../../assets/configFiles/favicon2.png'
import { Helmet } from 'react-helmet'

const CamperPage = () => {
  const idCamper = window.location.pathname.split('/')[2]
  const [camper, setCamper] = useState(null)
  useEffect(() => {
    getAllCampers().then((res) => {
      if (res) {
        const temp = []
        res.forEach(el => {
          if (el.id === idCamper) {
            temp.push(el)
          }
        })
        setCamper(temp[0])
      }
    })
  }, [])
  return (
    <>
      <Helmet>
        <meta charSet="utf-8"/>
        <title>
          {DomainHelper.isAp() ? 'ApCamper - wyprawy kamperowe, Wynajem kamperów Koszalin' : 'Adventurepeople - sport, przygoda i wyprawy'}
        </title>
        <link
          rel="icon"
          href={DomainHelper.isAp() ? favicon : favicon2}
        />
      </Helmet>
      <HeadingPage
        small="Wybierz kampera dla siebie"
        name="Wynajem kampera u nas to dosłownie parę kroków"
      />
      <ShowCamper data={camper}/>
    </>
  )
}

export default CamperPage
