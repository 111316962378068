import { CircularProgress, Grid } from '@mui/material'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Button from '@mui/material/Button'
import { useEffect, useState } from 'react'
import { getAllTrips } from '../../../firebase'
import { useHistory } from 'react-router'
import BreakpointsHelper from '../../../helpers/BreakpointsHelper/BreakpointsHelper'

const Offers = ({ space = false }) => {
  const history = useHistory()
  const pathname = window.location.pathname
  const [filterQuery, setFilterQuery] = useState(history?.location?.search?.replace('?', '').split('=')[1]?.replace('%20', ' '))
  const [trips, setTrips] = useState(null)
  const style = {
    filterEl: {
      border: '1px solid #324b54',
      marginBottom: '5px',
      marginRight: '10px',
      padding: '10px 15px !important',
      height: '50px',
      fontSize: '24px',
      cursor: 'pointer'
    },
    filterActiveEl: {
      background: '#324b54',
      color: 'white',
      marginBottom: '5px',
      marginRight: '10px',
      padding: '10px 15px !important',
      height: '50px',
      fontSize: '24px',
      cursor: 'pointer'
    }
  }
  const handleClick = (id) => history.push(`/trip/${id}`)
  const handleFilter = (filterEl = null) => {
    if (filterEl) {
      setFilterQuery(filterEl)
      history.push(`${pathname}?filter=${filterEl}`)
    } else {
      setFilterQuery(null)
      history.push(pathname)
    }
  }
  useEffect(() => {
    getAllTrips().then((res) => {
      if (res) {
        const temp = []
        if (filterQuery) {
          res?.forEach(el => {
            const type = el?.type
            type.forEach(filterElement => {
              if (filterElement.trim() === filterQuery.trim()) {
                temp.push(el)
              }
            })
          })
        } else {
          res?.forEach(el => {
            temp.push(el)
          })
        }
        temp?.sort((a, b) => {
          return new Date(a.startDate) - new Date(b.startDate)
        })
        setTrips(temp)
      }
    })
  }, [filterQuery])
  return (
    <Box
      sx={{
        display: 'flex',
        width: 'calc(100% - 100px)',
        alignItems: 'center',
        justifyContent: 'center',
        paddingLeft: '50px',
        paddingRight: '50px',
        flexDirection: 'column',
        marginTop: space && '100px',
        marginBottom: '100px'
      }}
    >
      <Grid container spacing={4}>
        <Grid item md={12} xs={12}>
          <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column' }}>
            <Typography
              sx={{
                marginBottom: '30px',
                fontSize: '30px',
                fontWeight: '900',
                textTransform: 'uppercase',
                textAlign: BreakpointsHelper.isMobile() ? 'center' : 'left'
              }}
            >
              Filtrowanie wypraw
            </Typography>
            <Box sx={{
              display: 'flex',
              alignItems: 'center',
              marginTop: '30px',
              justifyContent: 'center',
              textAlign: 'center',
              marginLeft: BreakpointsHelper.isMobile() ? '30px' : '0'
            }}>
              <Grid container spacing={4} sx={{ justifyContent: 'center' }}>
                <Grid
                  item
                  md={2}
                  xs={12}
                  sx={!filterQuery ? style.filterActiveEl : style.filterEl}
                  onClick={() => handleFilter(null)}
                >
                  Wszystkie
                </Grid>
                <Grid
                  item
                  md={3}
                  xs={12}
                  sx={filterQuery === 'Wyprawy w Polsce' ? style.filterActiveEl : style.filterEl}
                  onClick={() => handleFilter('Wyprawy w Polsce')}
                >
                  Wyprawy w Polsce
                </Grid>
                <Grid
                  item
                  md={3}
                  xs={12}
                  sx={filterQuery === 'Wyprawy zagraniczne' ? style.filterActiveEl : style.filterEl}
                  onClick={() => handleFilter('Wyprawy zagraniczne')}
                >
                  Wyprawy zagraniczne
                </Grid>
                <Grid
                  item
                  md={3}
                  xs={12}
                  sx={filterQuery === 'Wyprawy kamperem' ? style.filterActiveEl : style.filterEl}
                  onClick={() => handleFilter('Wyprawy kamperem')}
                >
                  Wyprawy kamperem
                </Grid>
                <Grid
                  item
                  md={3}
                  xs={12}
                  sx={filterQuery === 'Wyprawy sportowe' ? style.filterActiveEl : style.filterEl}
                  onClick={() => handleFilter('Wyprawy sportowe')}
                >
                  Wyprawy sportowe
                </Grid>
              </Grid>
            </Box>
          </Box>
        </Grid>
        {
          trips ? (
            <>
              {
                trips?.map((el, index) => {
                  return (
                    <Grid item md={4} xs={12} key={index}>
                      <Box
                        onClick={() => handleClick(el?.id)}
                        className="offer"
                        sx={{
                          display: 'flex',
                          flexDirection: 'column',
                          width: '100%',
                          boxShadow: '2px 2px 20px 10px rgba(0, 0, 0, 0.07)'
                        }}
                      >
                        <Box
                          sx={{
                            overflow: 'hidden'
                          }}
                        >
                          <img
                            style={{
                              width: '100%',
                              height: '320px',
                              objectFit: 'cover'
                            }}
                            src={el?.image}
                            alt=""
                          />
                        </Box>
                        <Box
                          sx={{
                            padding: '30px 20px 30px 20px',
                            display: 'flex',
                            flexDirection: 'column',
                            position: 'relative'
                          }}
                        >
                          <Typography
                            sx={{
                              textAlign: 'center',
                              fontSize: '22px',
                              position: 'relative',
                              zIndex: 1
                            }}
                          >
                            {el?.name}
                          </Typography>
                          <Typography
                            sx={{
                              textAlign: 'center',
                              fontSize: '22px',
                              position: 'relative',
                              zIndex: 1
                            }}
                          >
                            {el?.startDate} - {el?.endDate}
                          </Typography>
                          <Button
                            className="button"
                            variant="contained"
                            onClick={() => handleClick(el.id)}
                            sx={{ zIndex: 1, minWidth: '200px', padding: '10px', marginTop: '20px' }}
                          >
                            Zarezerwuj
                          </Button>
                        </Box>
                      </Box>
                    </Grid>
                  )
                })
              }
            </>
          ) : (
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                width: '100%',
                marginTop: '30px',
                minHeight: '300px'
              }}>
              <CircularProgress sx={{ color: '#2a3270' }}/>
            </Box>
          )
        }
        {
          trips?.length === 0 && (
            <Box sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              width: '100%',
              marginTop: '30px',
              minHeight: '300px'
            }}>
              <Typography
                sx={{
                  fontSize: '24px',
                  textAlign: 'center',
                  padding: '30px'
                }}
              >
                Brak dostępnych wypraw w tej kategorii.
              </Typography>
            </Box>
          )
        }
      </Grid>
    </Box>
  )
}

export default Offers
