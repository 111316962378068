import { ReactComponent as SvgIcon } from '../../../assets/images/section-title-vector.svg'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import DomainHelper from '../../../helpers/DomainHelper/DomainHelper'
import BreakpointsHelper from '../../../helpers/BreakpointsHelper/BreakpointsHelper'

const Heading = ({ name, small }) => {
  const style = {
    root: {
      display: 'flex',
      width: '100%',
      alignItems: 'center',
      justifyContent: 'center',
      marginTop: '50px',
      flexDirection: 'column',
      marginBottom: '100px'
    },
    name: {
      fontSize: BreakpointsHelper.isMobile() ? '36px' : '50px',
      marginBottom: '10px',
      fontWeight: '900',
      textAlign: 'center',
      color: DomainHelper.isAp() ? '#324b54' : '#575152'
    },
    small: {
      fontSize: '26px',
      color: '#4592b3',
      fontWeight: 600,
      textAlign: 'center',
      textTransform: 'uppercase'
    }
  }
  return (
    <Box sx={style.root}>
      <Typography sx={style.small}>{small}</Typography>
      <Typography sx={style.name}>{name}</Typography>
      <SvgIcon/>
    </Box>
  )
}

export default Heading
