import Typography from '@mui/material/Typography'
import { ReactComponent as FlyShape1 } from '../../../assets/images/baner/fly-shape1.svg'
import { ReactComponent as Cloud1 } from '../../../assets/images/baner/cloud1.svg'
import { ReactComponent as Cloud2 } from '../../../assets/images/baner/cloud2.svg'
import { Box } from '@mui/material'

const HeadingPage = ({ name, small, background }) => {
  const style = {
    root: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      height: '400px',
      width: '100%',
      position: 'relative',
      flexDirection: 'column',
      boxShadow: '2px 2px 20px 10px rgba(0, 0, 0, 0.02)',
      background: `url(${background}) center center / 100% auto no-repeat`
    },
    title: {
      fontSize: '40px',
      maxWidth: '600px',
      textAlign: 'center',
      lineHeight: '60px',
      marginTop: '10px',
      marginBottom: '30px',
      fontWeight: '900',
      color: background && 'white',
      zIndex: 1
    },
    small: {
      textTransform: 'uppercase',
      color: background ? 'white' : '#5ba3c1',
      fontSize: '24px',
      zIndex: 1
    },
    shadow: {
      position: 'absolute',
      background: 'rgba(0,0,0,.6)',
      width: '100%',
      height: '100%'
    }
  }
  return (
    <div style={style.root}>
      {background && <Box sx={style.shadow}/>}
      <Typography sx={style.small}>
        {small}
      </Typography>
      <Typography sx={style.title}>
        {name}
      </Typography>
      {
        !background && (
          <>
            <FlyShape1
              style={{
                position: 'absolute',
                zIndex: 0,
                top: '300px',
                marginRight: '-500px'
              }}
            />
            <Cloud1
              style={{
                position: 'absolute',
                zIndex: 0,
                top: '100px',
                left: '400px'
              }}
            />
            <Cloud2
              style={{
                position: 'absolute',
                zIndex: 0,
                top: '100px',
                right: 200,
                marginLeft: '-200px'
              }}
            />
          </>
        )
      }
    </div>
  )
}

export default HeadingPage
