import React, { useEffect } from 'react'
import { Route } from 'react-router-dom'
import LocalStorageHelper from '../../helpers/LocalStorageHelper/LocalStorageHelper'

export const PrivateRoute = (props) => {
  const { component: Component, layout: Layout } = props
  useEffect(() => {
    const user = LocalStorageHelper.get('user')
    if (!user) window.open('/login', '_self')
  }, [])
  return <Route render={(props) => <Layout component={Component} location={props}/>}/>
}
