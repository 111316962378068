import PropTypes from 'prop-types'
import NavigationPreview from '../../components/molecules/NavigationPreview/NavigationPreview'
import MagicLoader from '../../components/atoms/MagicLoader/MagicLoader'
import DomainHelper from '../../helpers/DomainHelper/DomainHelper'
import MagicLoaderAd from '../../components/atoms/MagicLoaderAd/MagicLoaderAd'

const PreviewLayout = (props) => {
  const Component = props.component
  const { location } = props
  const style = {
    root: {
      display: 'flex',
      alignItems: 'flex-start',
      justifyContent: 'start',
      flexDirection: 'column',
      padding: '90px 0 0 0',
      width: '100%'
    }
  }
  return (
    <div style={style.root}>
      {
        DomainHelper.isAp() ? (
          <MagicLoader>
            <NavigationPreview location={location}/>
            <Component location={location}/>
          </MagicLoader>
        ) : (
          <MagicLoaderAd>
            <NavigationPreview location={location}/>
            <Component location={location}/>
          </MagicLoaderAd>
        )
      }
    </div>
  )
}

PreviewLayout.defaultProps = {
  location: false
}

PreviewLayout.propsType = {
  location: PropTypes.object
}

export default PreviewLayout
