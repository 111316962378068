import FormGenerator from '../../components/organisms/FormGenerator/FormGenerator'
import { useHistory } from 'react-router'
import { useEffect, useState } from 'react'
import BreakpointsHelper from '../../helpers/BreakpointsHelper/BreakpointsHelper'
import { createOrUpdatePosts, getPostById } from '../../firebase'
import DomainHelper from '../../helpers/DomainHelper/DomainHelper'
import favicon from '../../assets/configFiles/favicon.png'
import favicon2 from '../../assets/configFiles/favicon2.png'
import { Helmet } from 'react-helmet'

const EditPostPage = () => {
  const idPost = window.location.pathname.split('/')[2]
  const history = useHistory()
  const [formData, setFormData] = useState(null)
  const [data, setData] = useState(null)
  const style = {
    root: {
      width: BreakpointsHelper.isMobile() ? 'calc(100% - 30px)' : 'calc(100% - 110px)',
      padding: BreakpointsHelper.isMobile() ? '15px' : '20px 50px'
    }
  }
  const getFormData = () => {
    const tempData = {
      elements: [
        {
          name: 'name',
          type: 'text',
          value: data?.name,
          label: 'Nazwa posta'
        },
        {
          name: 'image',
          type: 'text',
          value: data?.image,
          label: 'Zdjęcie'
        },
        {
          name: 'description',
          type: 'editor',
          value: data?.description,
          label: 'Opis'
        },
        {
          name: 'type',
          type: 'select',
          items: [{ name: 'Wyprawy', value: 'Wyprawy' }, { name: 'Kampery', value: 'Kampery' }],
          value: data?.type,
          label: 'Typ'
        },
        {
          type: 'button',
          value: 'Zapisz posta'
        }
      ]
    }
    setFormData(tempData)
  }
  const updatePost = (e) => {
    createOrUpdatePosts(e, idPost).then((res) => {
      if (res) history.push('/admin?updateCamper=true')
    })
  }
  useEffect(() => {
    if (idPost?.length > 0) {
      getPostById(idPost).then((res) => res && setData(res))
    }
  }, [idPost])
  useEffect(() => {
    if (data) {
      getFormData()
    }
  }, [data])
  return (
    <>
      <Helmet>
        <meta charSet="utf-8"/>
        <title>
          {DomainHelper.isAp() ? 'ApCamper - wyprawy kamperowe, Wynajem kamperów Koszalin' : 'Adventurepeople - sport, przygoda i wyprawy'}
        </title>
        <link
          rel="icon"
          href={DomainHelper.isAp() ? favicon : favicon2}
        />
      </Helmet>
      <div style={style.root}>
        {formData && data && <FormGenerator data={formData} submit={(e) => updatePost(e)}/>}
      </div>
    </>
  )
}

export default EditPostPage
