import { useEffect, useState } from 'react'
import HeadingPage from '../../components/atoms/HeadingPage/HeadingPage'
import { Grid, TextField } from '@mui/material'
import Box from '@mui/material/Box'
import PostsList from '../../components/atoms/PostsList/PostsList'
import { getAllPosts } from '../../firebase'
import DomainHelper from '../../helpers/DomainHelper/DomainHelper'
import favicon from '../../assets/configFiles/favicon.png'
import favicon2 from '../../assets/configFiles/favicon2.png'
import { Helmet } from 'react-helmet'

const BlogPage = () => {
  const style = {
    blog: {
      width: 'calc(100% - 100px)',
      padding: '100px 50px'
    },
    search: {
      width: '100%',
      marginTop: '10px'
    }
  }
  const [data, setData] = useState(null)
  const [defaultData, setDefaultData] = useState(null)
  const handleChange = (value) => {
    if (value?.length >= 3) {
      const tempData = []
      defaultData?.forEach(el => {
        if (el?.name.toLowerCase()?.search(value.toLowerCase()) >= 0) {
          tempData.push(el)
        }
      })
      setData(tempData)
    } else {
      setData(defaultData)
    }
  }
  useEffect(() => {
    getAllPosts().then(res => {
      if (res) {
        const temp = []
        if (DomainHelper.isAp()) {
          res?.forEach(el => {
            if (el?.type === 'Kampery') {
              temp.push(el)
            }
          })
        }
        if (DomainHelper.isAd()) {
          res?.forEach(el => {
            if (el?.type === 'Wyprawy') {
              temp.push(el)
            }
          })
        }
        setData(temp)
        setDefaultData(temp)
      }
    })
  }, [])
  return (
    <>
      <Helmet>
        <meta charSet="utf-8"/>
        <title>
          {DomainHelper.isAp() ? 'ApCamper - wyprawy kamperowe, Wynajem kamperów Koszalin' : 'Adventurepeople - sport, przygoda i wyprawy'}
        </title>
        <link
          rel="icon"
          href={DomainHelper.isAp() ? favicon : favicon2}
        />
      </Helmet>
      <HeadingPage
        small="Przeczytaj o nas"
        name="Aktualności i nowości"
      />
      <Box className="post" sx={style.blog}>
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <TextField
              onKeyDown={(e) => handleChange(e.target.value)}
              label="Znajdź temat, który cię interesuje"
              sx={style.search}
            />
          </Grid>
          <Grid item xs={12}>
            <PostsList data={data}/>
          </Grid>
        </Grid>
      </Box>
    </>
  )
}

export default BlogPage
