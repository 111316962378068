import { Grid } from '@mui/material'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Button from '@mui/material/Button'
import { useHistory } from 'react-router'
import { useEffect, useState } from 'react'
import { getAllCampers } from '../../../firebase'

const Campers = ({ space = false }) => {
  const history = useHistory()
  const [campers, setCampers] = useState(null)
  const handleClick = (el) => history.push(`/camper/${el?.id}`)
  useEffect(() => {
    getAllCampers().then((res) => {
      if (res) {
        setCampers(res)
      }
    })
  }, [])
  return (
    <Box
      sx={{
        display: 'flex',
        width: 'calc(100% - 100px)',
        alignItems: 'center',
        justifyContent: 'center',
        paddingLeft: '50px',
        paddingRight: '50px',
        flexDirection: 'column',
        marginTop: space && '100px',
        marginBottom: '100px'
      }}
    >
      <Grid container spacing={4}>
        {
          campers?.map((el, index) => {
            return (
              <Grid item md={4} xs={12} key={index}>
                <Box
                  className="offer"
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    width: '100%',
                    boxShadow: '2px 2px 20px 10px rgba(0, 0, 0, 0.07)'
                  }}
                >
                  <Box
                    sx={{
                      overflow: 'hidden'
                    }}
                  >
                    <img
                      style={{
                        width: '100%',
                        height: '320px',
                        objectFit: 'cover'
                      }}
                      src={el?.image[0]}
                      alt=""
                    />
                  </Box>
                  <Box
                    sx={{
                      padding: '50px 20px 30px 20px',
                      display: 'flex',
                      flexDirection: 'column',
                      position: 'relative'
                    }}
                  >
                    {
                      el?.priceBefore && 100 - Math.round(Number(el?.priceBefore) * 100 / Number(el?.pricePerDay)) < 0 && (
                        <Box
                          sx={{
                            position: 'absolute',
                            display: 'flex',
                            marginLeft: 'calc(50% - (140px / 2))',
                            alignItems: 'center',
                            justifyContent: 'center',
                            width: '40px',
                            height: '40px',
                            top: '-65px',
                            left: 0,
                            textAlign: 'center',
                            background: 'white',
                            padding: '50px',
                            borderRadius: '50%'
                          }}
                        >
                          <Typography
                            sx={{
                              marginTop: '-60px',
                              fontSize: '24px',
                              color: '#5ba3c1',
                              fontWeight: '900'
                            }}
                          >
                            {100 - Math.round(Number(el?.priceBefore) * 100 / Number(el?.pricePerDay))}%
                          </Typography>
                        </Box>
                      )
                    }
                    <Typography
                      sx={{
                        textAlign: 'center',
                        fontSize: '24px',
                        position: 'relative',
                        zIndex: 1
                      }}
                    >
                      {el?.name}
                    </Typography>
                    <Typography
                      sx={{
                        textAlign: 'center',
                        fontWeight: '900',
                        fontSize: '18px',
                        position: 'relative',
                        zIndex: 1
                      }}
                    >
                      Od {el?.pricePerDay} / dzień
                    </Typography>
                    {
                      el?.rented
                        ? (
                          <Button
                            className="button"
                            variant="outlined"
                            sx={{ zIndex: 1, minWidth: '200px', padding: '10px', marginTop: '20px', cursor: 'auto' }}
                          >
                            Wynajęty
                          </Button>
                        )
                        : (
                          <Button
                            className="button"
                            variant="contained"
                            onClick={() => handleClick(el)}
                            sx={{ zIndex: 1, minWidth: '200px', padding: '10px', marginTop: '20px' }}
                          >
                            Zarezerwuj
                          </Button>
                        )
                    }
                  </Box>
                </Box>
              </Grid>
            )
          })
        }
      </Grid>
    </Box>
  )
}

export default Campers
