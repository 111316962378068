import React, { useEffect } from 'react'
import { Button, Typography } from '@mui/material'
import LocalStorageHelper from '../../helpers/LocalStorageHelper/LocalStorageHelper'
import FormGenerator from '../../components/organisms/FormGenerator/FormGenerator'
import UserHelper from '../../helpers/UserHelper/UserHelper'
import { useSnackbar } from 'notistack'
import BreakpointsHelper from '../../helpers/BreakpointsHelper/BreakpointsHelper'
import CookiesHelper from '../../helpers/CookiesHelper/CookiesHelper'
import DomainHelper from '../../helpers/DomainHelper/DomainHelper'
import favicon from '../../assets/configFiles/favicon.png'
import favicon2 from '../../assets/configFiles/favicon2.png'
import { Helmet } from 'react-helmet'

const LoginPage = (props) => {
  const { enqueueSnackbar } = useSnackbar()
  const formData = {
    elements: [
      {
        name: 'email',
        type: 'email',
        label: 'Email',
        validationType: 'string'
      },
      {
        name: 'password',
        type: 'password',
        label: 'Hasło',
        helperText: 'Hasło musi się składać z min. 8 znaków.',
        validationType: 'string'
      },
      {
        type: 'button',
        value: 'Zaloguj'
      }
    ]
  }
  const style = {
    root: {
      background: 'white',
      padding: BreakpointsHelper.isMobile() ? '15px' : '50px',
      fontSize: BreakpointsHelper.isMobile() ? '24px' : '25px',
      fontWeight: 700,
      maxWidth: BreakpointsHelper.isMobile() ? 'none' : '320px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      flexDirection: 'column',
      textAlign: 'center'
    },
    desc: {
      maxWidth: '320px',
      fontSize: BreakpointsHelper.isMobile() ? '16px' : '18px',
      fontWeight: BreakpointsHelper.isMobile() ? '400' : '300',
      textTransform: 'none',
      textAlign: 'center'
    },
    icon: {
      marginRight: '5px'
    }
  }
  const createData = async (res) => {
    const data = {
      accessToken: res?.res?.accessToken,
      uid: res?.res?.uid,
      email: res?.res?.email,
      photoURL: res?.res?.photoURL,
      role: res?.database?.role || 'user',
      phone: res?.database?.phone,
      name: res?.database?.name,
      apartment: res?.database?.apartment,
      street: res?.database?.street,
      house: res?.database?.house,
      defaultLang: res?.database?.defaultLang
    }
    LocalStorageHelper.set('user', data)
    return data
  }
  const handleLogin = (e) => {
    UserHelper.login(e).then(res => {
      if (res) {
        if (res?.res?.emailVerified) {
          createData(res).then(res => {
            if (res) {
              if (res.role === 'admin') {
                props.location.history.push('/admin')
              } else {
                enqueueSnackbar('Przykro mi nie masz uprawnień do wejścia. Poproś administratora o nadanie uprawnień.', { variant: 'warning' })
              }
            }
          })
        }
        if (!res?.res?.emailVerified) enqueueSnackbar('Dane do logowanie są niepoprawne, bądź konto nie jest aktywowane z emaila za pomocą linku.', { variant: 'warning' })
      } else enqueueSnackbar('Wystąpił problem z logowaniem.', { variant: 'error' })
    }).catch(() => {
      enqueueSnackbar('Dane do logowanie są niepoprawne.', { variant: 'error' })
    })
  }
  useEffect(() => {
    if (LocalStorageHelper.get('user')) {
      const savePassword = CookiesHelper.get('savePassword')
      const saveEmail = CookiesHelper.get('saveEmail')
      if (saveEmail && savePassword) {
        props.location.history.push('/admin')
      }
    }
  }, [])
  return (
    <>
      <Helmet>
        <meta charSet="utf-8"/>
        <title>
          {DomainHelper.isAp() ? 'ApCamper - wyprawy kamperowe, Wynajem kamperów Koszalin' : 'Adventurepeople - sport, przygoda i wyprawy'}
        </title>
        <link
          rel="icon"
          href={DomainHelper.isAp() ? favicon : favicon2}
        />
      </Helmet>
      <div style={style.root}>
        <Button
          sx={{ position: 'absolute', top: '20px', left: '20px' }}
          variant="outlined"
          onClick={() => props.location.history.push('/home')}
        >
          Powrót do strony głównej
        </Button>
        Logowanie
        <div style={style.desc}>
          Zaloguj się za pomocą emaila oraz hasła.
        </div>
        <FormGenerator data={formData} submit={(e) => handleLogin(e)} {...props}/>
        <Button
          sx={{ marginBottom: '10px', maxWidth: '320px' }}
          fullWidth
          variant="outlined"
          onClick={() => props?.location?.history?.push('/register')}
        >
          Zarejestruj
        </Button>
        <Typography
          sx={{ marginBottom: '20px', marginTop: '20px', textTransform: 'lowercase', fontWeight: '700' }}
        >
          Nie pamiętasz hasła ?
        </Typography>
        <Button
          sx={{ marginBottom: '10px' }}
          fullWidth
          variant="outlined"
          onClick={() => props?.location?.history?.push('/reset')}
        >
          Zresetuj hasło
        </Button>
      </div>
    </>
  )
}

export default LoginPage
