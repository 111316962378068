import { Grid } from '@mui/material'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import FormGenerator from '../../organisms/FormGenerator/FormGenerator'
import DomainHelper from '../../../helpers/DomainHelper/DomainHelper'

const Contacts = () => {
  const sendEmail = (e) => {
    window.open(`mailto:${e.email}?subject=${e.subject}&body=${'Phone: ' + e.phone + ', ' + e.message}`)
  }
  const formData = {
    elements: [
      {
        type: 'text',
        label: 'Imię i nazwisko / Firma',
        name: 'name',
        validationType: 'string'
      },
      {
        type: 'text',
        label: 'Email',
        name: 'email',
        validationType: 'string'
      },
      {
        type: 'text',
        label: 'Telefon',
        name: 'phone',
        validationType: 'string'
      },
      {
        type: 'text',
        label: 'Temat',
        name: 'subject',
        validationType: 'string'
      },
      {
        type: 'editor',
        label: 'Treść wiadomości',
        name: 'message',
        validationType: 'string'
      },
      {
        type: 'button',
        value: 'Wyślij'
      }
    ]
  }
  const style = {
    root: {
      width: 'calc(100% - 100px)',
      padding: '0 50px',
      display: 'flex',
      maxWidth: '1400px',
      margin: '100px auto'
    },
    icon: {},
    title: {
      fontSize: '35px',
      fontWeight: '600'
    },
    text: {
      fontSize: '25px'
    },
    box: {
      marginBottom: '50px',
      textAlign: 'center',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      flexDirection: 'column',
      border: '1px solid black',
      padding: '30px',
      borderRadius: '3px',
      minHeight: '150px'
    }
  }
  const data = [
    {
      icon: '',
      title: 'Adres',
      text: DomainHelper.isAp() ? 'ul. Andersa 16a,<br/> 75-950 Koszalin' : 'ul. Andersa 16a,<br/> 75-950 Koszalin'
    },
    {
      icon: '',
      title: 'Kontakt',
      text: DomainHelper.isAp() ? 'Tel. 696 688 410<br/>Tel. 696 024 956' : 'Tel. 696 688 410<br/>Tel. 696 024 956'
    },
    {
      icon: '',
      title: 'Adres email',
      text: DomainHelper.isAp() ? 'rent@apcamper.pl' : ''
    },
    {
      icon: '',
      title: 'Chat',
      text: 'dostępny w prawym dolnym rogu'
    }
  ]
  return (
    <Box sx={style.root}>
      <Grid container spacing={8}>
        {
          data?.map((el, index) => {
            return (
              <Grid key={index} item md={3} xs={12}>
                <Box sx={style.box}>
                  <Box sx={style.icon}>
                    {el.icon}
                  </Box>
                  <Typography sx={style.title}>{el.title}</Typography>
                  <Typography sx={style.text} dangerouslySetInnerHTML={{ __html: el.text }}></Typography>
                </Box>
              </Grid>
            )
          })
        }
        <Grid item md={6}>
          <Typography sx={{ fontSize: '45px', fontWeight: '600', textTransform: 'uppercase' }}>
            Skontaktuj się z nami
          </Typography>
          <Typography sx={{ fontSize: '25px', marginBottom: '30px' }}>
            Chcemy zapewnić Ci najlepszą obsługę podczas wynajmu kampera, dlatego nasi doświadczeni pracownicy są gotowi
            udzielić Ci wszelkich informacji i odpowiedzieć na pytania dotyczące naszych pojazdów oraz wynajmu.
          </Typography>
          <FormGenerator data={formData} submit={(e) => sendEmail(e)}/>
        </Grid>
        <Grid item md={6}>
          <Typography sx={{ fontSize: '45px', fontWeight: '600', textTransform: 'uppercase' }}>
            Znajdź nas
          </Typography>
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2334.476837024594!2d16.185192716000753!3d54.189340618933265!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4701cd10a2cb1717%3A0xc9dda420d43968f6!2sW%C5%82adys%C5%82awa%20Andersa%2016a%2C%2075-015%20Koszalin!5e0!3m2!1sen!2spl!4v1680036559296!5m2!1sen!2spl"
            width="100%"
            height="600"
            allowFullScreen=""
            loading="lazy"
            style={{ border: 0, marginTop: '10px' }}
            referrerPolicy="no-referrer-when-downgrade"
          >
          </iframe>
        </Grid>
      </Grid>
    </Box>
  )
}

export default Contacts
