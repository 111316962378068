import { Box, Button, FormControl, Grid, InputLabel, MenuItem, Select, TextField } from '@mui/material'
import Typography from '@mui/material/Typography'
import { useEffect, useState } from 'react'
import { useSnackbar } from 'notistack'
import { createOrUpdateReservationsTrips, getAllCampers } from '../../../firebase'
import DomainHelper from '../../../helpers/DomainHelper/DomainHelper'

const ShowTrip = (props) => {
  const { data } = props
  const { enqueueSnackbar } = useSnackbar()
  const [people, setPeople] = useState(1)
  const [email, setEmail] = useState('')
  const [phone, setPhone] = useState('')
  const [campers, setCampers] = useState(null)
  const [camper, setCamper] = useState(null)
  const handleSave = (e) => {
    e.preventDefault()
    const sendData = data
    sendData.email = email
    sendData.phone = phone
    sendData.people = people
    sendData.camper = camper
    createOrUpdateReservationsTrips(sendData).then((res) => {
      if (res) {
        enqueueSnackbar('Dodano rezerwacje, będziemy się kontaktować w celu jej akceptacji oraz dalszych kroków.', { variant: 'success' })
      }
    })
  }
  const style = {
    root: {
      padding: '50px',
      display: 'flex',
      width: 'calc(100% - 100px)',
      alignItems: 'center',
      maxWidth: '1400px',
      margin: '0 auto'
    },
    image: {
      display: 'flex',
      alignItems: 'center',
      width: '100%',
      margin: '0 auto'
    },
    name: {
      fontSize: '30px !important',
      fontWeight: '900'
    },
    nameSmall: {
      fontSize: '30px !important',
      fontWeight: '400',
      marginBottom: '20px'
    },
    nameColor: {
      fontSize: '35px',
      fontWeight: '900',
      color: '#D5F0FC',
      background: DomainHelper.isAp() ? '#324b54' : '#575152',
      borderRadius: '3px',
      padding: '5px 20px',
      textAlign: 'center',
      marginBottom: '20px',
      maxWidth: '50%'
    },
    desc: {
      fontSize: '25px'
    }
  }
  useEffect(() => {
    getAllCampers().then((res) => {
      if (res) {
        const temp = []
        res?.forEach(el => {
          if (el?.rented === false || !el?.rented) {
            temp.push(el)
          }
        })
        setCampers(temp)
      }
    })
  }, [])
  return (
    <Box sx={style.root}>
      <Grid container spacing={4}>
        <Grid item md={6}>
          <Grid container spacing={2}>
            <Grid item md={12}>
              <Typography sx={style.name}>
                {data?.name}
              </Typography>
              <Typography sx={style.nameSmall}>
                od {data?.startDate} do {data?.endDate}
              </Typography>
              <Typography sx={style.nameColor}>
                {data?.price}.00&nbsp;zł&nbsp;brutto
              </Typography>
            </Grid>
            <Grid item md={6}>
              <form onSubmit={(e) => handleSave(e)}>
                <Box>
                  <TextField
                    sx={{ marginBottom: '20px' }}
                    fullWidth
                    label="Podaj email"
                    type="email"
                    value={email}
                    required
                    onChange={(e) => setEmail(e.target.value)}/>
                  <TextField
                    sx={{ marginBottom: '20px' }}
                    fullWidth
                    label="Podaj telefon"
                    type="tel"
                    value={phone}
                    required
                    onChange={(e) => setPhone(e.target.value)}/>
                  <TextField
                    fullWidth
                    label="Ilość osób"
                    type="number"
                    min={1}
                    defaultValue={1}
                    value={people}
                    required
                    onChange={(e) => setPeople(e.target.value)}/>
                  {
                    campers?.length > 0 && (
                      <FormControl
                        sx={{
                          margin: '0',
                          width: '100%',
                          marginTop: '15px'
                        }}
                      >
                        <InputLabel>Zarezerwuj dodatkowo kampera</InputLabel>
                        <Select
                          value={camper}
                          label={'Zarezerwuj dodatkowo kampera'}
                          name={'camper'}
                          required={false}
                          sx={{ height: '56px' }}
                          onChange={(e) => setCamper(e?.target?.value)}
                        >
                          {
                            campers?.map((menuItem, index) => (
                              <MenuItem key={index} value={menuItem}>
                                {menuItem.name}
                              </MenuItem>
                            ))
                          }
                        </Select>
                        <Typography sx={{ marginTop: '5px', marginBottom: '20px' }}>
                          Kamper jest dodatkowo płatny oraz po rezerwacji zostaniesz poinformowany o jego
                          dostępności oraz o wysokości opłat. Dodatkowo możesz sobie je sprawdzić na naszej stronie
                          internetowej w zakładce Kampery.
                        </Typography>
                      </FormControl>
                    )
                  }
                </Box>
                <Button
                  fullWidth
                  variant="contained"
                  sx={{
                    marginTop: '20px',
                    fontSize: '20px !important'
                  }}
                  type="submit"
                >
                  Zarezerwuj
                </Button>
              </form>
            </Grid>
          </Grid>
        </Grid>
        <Grid item md={6}>
          <img src={data?.image} alt="image" style={style.image}/>
          {
            data?.iframe && (
              <Box dangerouslySetInnerHTML={{ __html: data?.iframe }} sx={{ width: '100%', marginTop: '30px' }}/>
            )
          }
        </Grid>
        {
          data?.image1 && (
            <Grid item md={12} className="texts">

              <Typography sx={{ fontWeight: '900', fontSize: '35px !important' }}>GALERIA ZDJĘĆ</Typography>
              {
                data && (
                  <Grid container spacing={4}>
                    {
                      [...Array(10)].map((el, index) => {
                        return (
                          <Grid key={index} item md={4}>
                            {data[`image${index + 1}`] &&
                              <img src={data[`image${index + 1}`]} alt={`image${index + 1}`}
                                   style={{ width: '100%', objectFit: 'cover', height: '250px' }}/>}
                          </Grid>
                        )
                      })
                    }
                  </Grid>
                )
              }
            </Grid>
          )
        }
        <Grid item md={12} className="texts">
          <Typography sx={{ fontWeight: '900', fontSize: '35px !important' }}>TYP</Typography>
          <Typography dangerouslySetInnerHTML={{ __html: data?.type }}/>
        </Grid>
        <Grid item md={12} className="texts">
          <Typography sx={{ fontWeight: '900', fontSize: '35px !important' }}>DLA KOGO?</Typography>
          <Typography dangerouslySetInnerHTML={{ __html: data?.forWho }}/>
        </Grid>
        <Grid item md={12} className="texts">
          <Typography sx={{ fontWeight: '900', fontSize: '35px !important' }}>WAŻNE INFORMACJE</Typography>
          <Typography dangerouslySetInnerHTML={{ __html: data?.adds }}/>
        </Grid>
        <Grid item md={12} className="texts">
          <Typography sx={{ fontWeight: '900', fontSize: '35px !important' }}>W CENIE</Typography>
          <Typography dangerouslySetInnerHTML={{ __html: data?.priceExtended }}/>
        </Grid>
        <Grid item md={12} className="texts">
          <Typography sx={{ fontWeight: '900', fontSize: '35px !important' }}>POZA CENĄ</Typography>
          <Typography dangerouslySetInnerHTML={{ __html: data?.priceNotExtended }}/>
        </Grid>
        <Grid item md={12} className="texts">
          <Typography sx={{ fontWeight: '900', fontSize: '35px !important' }}>PŁATNOŚĆ</Typography>
          <Typography dangerouslySetInnerHTML={{ __html: data?.payment }}/>
        </Grid>
        <Grid item md={12} className="texts">
          <Typography sx={{ fontWeight: '900', fontSize: '35px !important' }}>HARMONOGRAM</Typography>
          <Typography dangerouslySetInnerHTML={{ __html: data?.schedule }}/>
        </Grid>
        <Grid item md={12} className="texts">
          <Typography sx={{ fontWeight: '900', fontSize: '35px !important' }}>TRANSPORT</Typography>
          <Typography dangerouslySetInnerHTML={{ __html: data?.transport }}/>
        </Grid>
        <Grid item md={12} className="texts">
          <Typography sx={{ fontWeight: '900', fontSize: '35px !important' }}>KONTAKT</Typography>
          <Typography dangerouslySetInnerHTML={{ __html: data?.contact }}/>
        </Grid>
      </Grid>
    </Box>
  )
}

export default ShowTrip
