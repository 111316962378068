import { useState } from 'react'
import BreakpointsHelper from '../../../helpers/BreakpointsHelper/BreakpointsHelper'
import logo from '../../../assets/configFiles/logo.png'
import logo2 from '../../../assets/configFiles/logov2.png'
import Box from '@mui/material/Box'
import MenuIcon from '@mui/icons-material/Menu'
import Typography from '@mui/material/Typography'
import DomainHelper from '../../../helpers/DomainHelper/DomainHelper'

const NavigationPreview = () => {
  const [open, setOpen] = useState(false)
  const style = {
    nav: {
      position: 'fixed',
      top: 0,
      right: 0,
      width: `${BreakpointsHelper.isMobile() ? 'calc(100% - 30px)' : 'calc(100% - 100px)'}`,
      margin: '0 auto',
      height: '90px',
      background: 'white',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      paddingRight: BreakpointsHelper.isMobile() ? 15 : 50,
      paddingLeft: BreakpointsHelper.isMobile() ? 15 : 50,
      zIndex: 999,
      boxShadow: '2px 2px 20px 10px rgba(0, 0, 0, 0.02)'
    },
    logo: {
      maxWidth: BreakpointsHelper.isMobile() ? '150px' : '320px',
      marginLeft: BreakpointsHelper.isMobile() ? '20px' : '0',
      cursor: 'pointer'
    },
    logoOpen: {
      height: BreakpointsHelper.isMobile() ? '55px' : '100%',
      marginLeft: BreakpointsHelper.isMobile() ? '0' : '35px',
      position: 'relative',
      left: '-5px',
      bottom: '-3px'
    },
    buttons: {
      display: 'flex',
      alignItems: 'center'
    },
    burgerOpen: {
      height: '50px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-end',
      position: 'relative',
      right: '-5px',
      bottom: '-2px',
      marginBottom: '30px'
    },
    elementNav: {
      fontSize: '18px',
      position: 'relative',
      marginLeft: BreakpointsHelper.isMobile() ? '0' : '40px',
      cursor: 'pointer',
      border: BreakpointsHelper.isMobile() && '1px solid black',
      padding: BreakpointsHelper.isMobile() && '20px',
      textAlign: BreakpointsHelper.isMobile() && 'center',
      marginBottom: BreakpointsHelper.isMobile() && '20px'
    },
    menuRight: {
      display: 'flex'
    }
  }
  const openLink = (link) => window.open(link, '_self')
  const generateMenu = () => {
    return (
      <>
        <Typography
          onClick={() => openLink('/about')}
          className={`elementNav ${window.location.pathname.replace('/', '') === 'about' && 'active'}`}
          sx={style.elementNav}
        >
          O nas
        </Typography>
        {
          DomainHelper.isAp() && (
            <Typography
              onClick={() => openLink(DomainHelper.isAp() ? '/campers' : 'https://apcamper.pl')}
              className={`elementNav ${window.location.pathname.replace('/', '') === 'campers' && 'active'}`}
              sx={style.elementNav}
            >
              Kampery
            </Typography>
          )
        }
        <Typography
          className={`elementNav tripsNav ${window.location.pathname.replace('/', '') === 'trips' && 'active'}`}
          sx={style.elementNav}
        >
          <Box
            onClick={() => openLink(DomainHelper.isAp() ? 'https://adventurepeople.eu/trips?filter=Wyprawy%20kamperem' : '/trips')}
          >
            Wyprawy
          </Box>
          {
            DomainHelper.isAd() && BreakpointsHelper.isMobile() && (
              <Box sx={{
                marginTop: '20px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                flexDirection: 'column'
              }}>
                <Typography
                  onClick={() => openLink('https://adventurepeople.eu/trips?filter=Wyprawy%20w%20Polsce')}
                  className={`elementNav ${window.location.pathname.replace('/', '') === 'trips' && 'active'}`}
                >
                  Wyprawy w Polsce
                </Typography>
                <Typography
                  onClick={() => openLink('https://adventurepeople.eu/trips?filter=Wyprawy%20zagraniczne')}
                  className={`elementNav ${window.location.pathname.replace('/', '') === 'trips' && 'active'}`}
                >
                  Wyprawy zagraniczne
                </Typography>
                <Typography
                  onClick={() => openLink('https://adventurepeople.eu/trips?filter=Wyprawy%20kamperem')}
                  className={`elementNav ${window.location.pathname.replace('/', '') === 'trips' && 'active'}`}
                >
                  Wyprawy kamperem
                </Typography>
                <Typography
                  onClick={() => openLink('https://adventurepeople.eu/trips?filter=Wyprawy%20sportowe')}
                  className={`elementNav ${window.location.pathname.replace('/', '') === 'trips' && 'active'}`}
                >
                  Wyprawy sportowe
                </Typography>
              </Box>
            )
          }
          {
            DomainHelper.isAd() && !BreakpointsHelper.isMobile() && (
              <Box sx={{
                display: 'none',
                alignItems: 'flex-start',
                justifyContent: 'center',
                flexDirection: 'column',
                position: 'absolute',
                background: 'white',
                width: '250px',
                padding: '40px 0 20px 0'
              }}>
                <Typography
                  onClick={() => openLink('https://adventurepeople.eu/trips?filter=Wyprawy%20w%20Polsce')}
                  className={`elementNav ${window.location.pathname.replace('/', '') === 'trips' && 'active'}`}
                  sx={style.elementNav}
                >
                  Wyprawy w Polsce
                </Typography>
                <Typography
                  onClick={() => openLink('https://adventurepeople.eu/trips?filter=Wyprawy%20zagraniczne')}
                  className={`elementNav ${window.location.pathname.replace('/', '') === 'trips' && 'active'}`}
                  sx={style.elementNav}
                >
                  Wyprawy zagraniczne
                </Typography>
                <Typography
                  onClick={() => openLink('https://adventurepeople.eu/trips?filter=Wyprawy%20kamperem')}
                  className={`elementNav ${window.location.pathname.replace('/', '') === 'trips' && 'active'}`}
                  sx={style.elementNav}
                >
                  Wyprawy kamperem
                </Typography>
                <Typography
                  onClick={() => openLink('https://adventurepeople.eu/trips?filter=Wyprawy%20sportowe')}
                  className={`elementNav ${window.location.pathname.replace('/', '') === 'trips' && 'active'}`}
                  sx={style.elementNav}
                >
                  Wyprawy sportowe
                </Typography>
              </Box>
            )
          }
        </Typography>
        {
          DomainHelper.isAd() && (
            <Typography
              onClick={() => openLink(DomainHelper.isAp() ? '/campers' : 'https://apcamper.pl')}
              className={`elementNav ${window.location.pathname.replace('/', '') === 'campers' && 'active'}`}
              sx={style.elementNav}
            >
              Kampery
            </Typography>
          )
        }
        <Typography
          onClick={() => openLink('/gallery')}
          className={`elementNav ${window.location.pathname.replace('/', '') === 'gallery' && 'active'}`}
          sx={style.elementNav}
        >
          Galeria
        </Typography>
        <Typography
          onClick={() => openLink('/blog')}
          className={`elementNav ${window.location.pathname.replace('/', '') === 'blog' && 'active'}`}
          sx={style.elementNav}
        >
          Blog
        </Typography>
        <Typography
          onClick={() => openLink('/contact')}
          className={`elementNav ${window.location.pathname.replace('/', '') === 'contact' && 'active'}`}
          sx={style.elementNav}
        >
          Kontakt
        </Typography>
      </>
    )
  }
  return (
    <div style={style.nav} className="navigation">
      {
        DomainHelper.isAp()
          ? (
            <img src={logo} onClick={() => openLink('/home')} alt={'logo'} style={style.logo}/>
          ) : (
            <img src={logo2} onClick={() => openLink('/home')} alt={'logo'} style={style.logo}/>
          )
      }
      <Box sx={style.menuRight}>
        {
          BreakpointsHelper.isMobile()
            ? (
              <div style={{ marginRight: '20px' }} onClick={() => setOpen(!open)}>
                <MenuIcon style={style.menu}/>
              </div>
            )
            : generateMenu()
        }
        {
          open && (
            <Box
              sx={{
                position: 'absolute',
                zIndex: '999',
                background: 'white',
                top: '90px',
                left: '0',
                height: 'calc(100vh - 90px)',
                width: 'calc(100% - 40px)',
                display: 'flex',
                flexDirection: 'column',
                padding: '20px'
              }}
            >
              {generateMenu()}
            </Box>
          )
        }
      </Box>
    </div>
  )
}

export default NavigationPreview
