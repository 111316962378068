import Box from '@mui/material/Box'
import { useEffect, useState } from 'react'
import { getAllGalleries } from '../../firebase'
import HeadingPage from '../../components/atoms/HeadingPage/HeadingPage'
import Typography from '@mui/material/Typography'
import { Grid } from '@mui/material'
import Lightbox from 'yet-another-react-lightbox'
import 'yet-another-react-lightbox/styles.css'
import DomainHelper from '../../helpers/DomainHelper/DomainHelper'
import favicon from '../../assets/configFiles/favicon.png'
import favicon2 from '../../assets/configFiles/favicon2.png'
import { Helmet } from 'react-helmet'

const GalleryPage = () => {
  const [gallery, setGallery] = useState(null)
  const [active, setActive] = useState(null)
  const [open, setOpen] = useState(false)
  const [slides, setSlides] = useState(null)
  const style = {
    box: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      marginTop: '50px',
      width: '100%'
    },
    element: {
      fontSize: '20px',
      fontWeight: '600',
      textTransform: 'uppercase',
      border: '1px solid #d5cfcf',
      padding: '10px 20px',
      borderRadius: '5px',
      background: '#51575b',
      color: 'white',
      marginRight: '20px',
      cursor: 'pointer',
      transition: 'all .3s',
      '&:hover': {
        background: '#90B956',
        transition: 'all .3s'
      }
    }
  }
  const handleActive = (el) => {
    setActive(el)
    const tempSlides = []
    el?.files?.forEach((el) => tempSlides.push({ src: el }))
    setSlides(tempSlides)
  }
  useEffect(() => {
    getAllGalleries().then((res) => {
      if (res) {
        const temp = []
        if (!res) {
          res?.forEach(el => {
            if (el?.type === 'Kampery') {
              temp.push(el)
            }
          })
        } else {
          res?.forEach(el => {
            if (el?.type === 'Wyprawy') {
              temp.push(el)
            }
          })
        }
        setGallery(temp)
        if (temp?.length > 0) {
          setActive(res[0])
          const tempSlides = []
          temp[0]?.files?.forEach((el) => tempSlides.push({ src: el }))
          setSlides(tempSlides)
        }
      }
    })
  }, [])
  return (
    <>
      <Helmet>
        <meta charSet="utf-8"/>
        <title>
          {DomainHelper.isAp() ? 'ApCamper - wyprawy kamperowe, Wynajem kamperów Koszalin' : 'Adventurepeople - sport, przygoda i wyprawy'}
        </title>
        <link
          rel="icon"
          href={DomainHelper.isAp() ? favicon : favicon2}
        />
      </Helmet>
      <HeadingPage
        small="Galeria"
        name="Zobacz coś ładnego co mamy do zaoferowania"
      />
      <Box sx={style.box}>
        {
          gallery?.map((el, index) => {
            if (el?.visibility) {
              return (
                <Box key={index} onClick={() => handleActive(el)} sx={style.element}>{el?.name}</Box>
              )
            } else return ''
          })
        }
      </Box>
      <Box sx={{ padding: '50px', width: 'calc(100% - 100px)' }}>
        {
          active?.name?.length > 0 ? (
            <Grid container spacing={4}>
              <Grid item md={12}>
                <Typography sx={{ marginBottom: '20px', fontSize: '22px' }}><b>Zdjęcia z sesji:</b> {active?.name}
                </Typography>
                <Grid container spacing={4}>
                  {
                    active?.files.map((el, index) => {
                      return (
                        <Grid item md={4} key={index}>
                          <img onClick={() => setOpen(true)} src={el} alt={index}
                               style={{ width: '100%', objectFit: 'cover', height: '300px', cursor: 'pointer' }}/>
                        </Grid>
                      )
                    })
                  }
                  {
                    slides?.length > 0 && (
                      <Lightbox
                        open={open}
                        close={() => setOpen(false)}
                        slides={slides}
                      />
                    )
                  }
                </Grid>
              </Grid>
            </Grid>
          ) : (
            <Typography sx={{ fontSize: '24px', textAlign: 'center', width: '100%' }}>
              Brak dostępnych elementów w galerii
            </Typography>
          )
        }
      </Box>
    </>
  )
}

export default GalleryPage
