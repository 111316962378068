import Button from '@mui/material/Button'
import Box from '@mui/material/Box'
import { Close } from '@mui/icons-material'
import Typography from '@mui/material/Typography'
import Dialog from '@mui/material/Dialog'
import { useEffect, useState } from 'react'
import DialogTitle from '@mui/material/DialogTitle'
import DialogContent from '@mui/material/DialogContent'
import DialogActions from '@mui/material/DialogActions'
import IconButton from '@mui/material/IconButton'
import { getAllCampers } from '../../../firebase'

const Reservation = (props) => {
  const { data, onDelete } = props
  const { name, idCamper, id, price, email, phone } = data
  const [open, setOpen] = useState(false)
  const [image, setImage] = useState('')
  const style = {
    root: {
      position: 'relative',
      padding: '20px',
      boxShadow: '3px 3px 20px 1px rgba(0,0,0,.1)',
      marginBottom: '20px',
      display: 'flex'
    },
    title: {
      fontSize: '20px',
      fontWeight: '600',
      textTransform: 'uppercase'
    },
    date: {},
    buttons: {
      position: 'absolute',
      right: '20px',
      top: '0',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-end',
      height: '100%'
    },
    buttonEdit: {},
    buttonDelete: {
      background: '#cd3838',
      marginRight: '20px'
    }
  }
  const handleDelete = () => {
    setOpen(true)
  }
  const editOrShow = () => {
    props.location.history.push(`/editReservation/${id}`)
  }
  const deleteElement = () => {
    setOpen(false)
    onDelete(id)
  }
  const getImageCamper = () => {
    getAllCampers().then((res) => {
      if (res) {
        const tempData = res?.find(el => el.id === idCamper)
        setImage(tempData?.image[0])
      }
    })
  }
  useEffect(() => {
    getImageCamper()
  }, [])
  return (
    <div style={style.root} className="reservation">
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <img
          src={image}
          alt={name}
          style={{ maxWidth: '80px', marginRight: '10px' }}
        />
      </Box>
      <Box>
        <div style={style.title}>
          {name}
        </div>
      </Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          marginLeft: '40px'
        }}
      >
        <Typography><b>ID:</b> {idCamper}</Typography>
        <Typography><b>Cena:</b> {price}.00 zł brutto</Typography>
        <Typography><b>Email:</b> {email}</Typography>
        <Typography><b>Phone:</b> {phone}</Typography>
      </Box>
      <div style={style.buttons}>
        <Button
          variant="contained"
          style={style.buttonDelete}
          onClick={() => handleDelete()}
        >
          Usuń rezerwację
        </Button>
        <Button
          variant="contained"
          style={style.buttonEdit}
          onClick={() => editOrShow()}
        >
          Podgląd rezerwacji
        </Button>
      </div>
      <Dialog
        open={open}
        onClose={() => setOpen(false)}
      >
        <DialogTitle>Usuń rezerwację</DialogTitle>
        <IconButton
          aria-label="close"
          onClick={() => setOpen(false)}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500]
          }}
        >
          <Close/>
        </IconButton>
        <DialogContent sx={{ padding: '20px' }}>
          Pamiętaj, że jak usuniesz rezerwację, to nie będzie już dostępna. Czy na pewno chcesz ją usunąć ?
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            sx={{
              background: '#cd3838'
            }}
            onClick={() => deleteElement()}
          >
            Usuń rezerwację
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}

export default Reservation
