import React from 'react'
import PropTypes from 'prop-types'
import './SimpleLayout.scss'
import MagicLoaderAd from '../../components/atoms/MagicLoaderAd/MagicLoaderAd'
import MagicLoader from '../../components/atoms/MagicLoader/MagicLoader'
import DomainHelper from '../../helpers/DomainHelper/DomainHelper'

const SimpleLayout = (props) => {
  const Component = props.component
  const { location } = props
  const style = {
    root: {
      display: 'flex',
      overflow: 'hidden',
      height: '100vh',
      alignItems: 'center',
      justifyContent: 'center',
      flexDirection: 'column'
    }
  }
  return (
    <div style={style.root}>
      {
        DomainHelper.isAp() ? (
          <MagicLoader>
            <Component location={location}/>
          </MagicLoader>
        ) : (
          <MagicLoaderAd>
            <Component location={location}/>
          </MagicLoaderAd>
        )
      }
    </div>
  )
}

SimpleLayout.defaultProps = {
  location: false
}

SimpleLayout.propsType = {
  location: PropTypes.object
}

export default SimpleLayout
