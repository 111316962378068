import { BrowserRouter as Router, Switch } from 'react-router-dom'
import MainLayout from './layouts/MainLayout/MainLayout'
import { Redirect } from 'react-router'
import './assets/scss/App.scss'
import { PublicRoute } from './router/PublicRoute/PublicRoute'
import LoginPage from './pages/LoginPage/LoginPage'
import SimpleLayout from './layouts/SimpleLayout/SimpleLayout'
import { PrivateRoute } from './router/PrivateRoute/PrivateRoute'
import HomePage from './pages/HomePage/HomePage'
import PreviewLayout from './layouts/PreviewLayout/PreviewLayout'
import RegisterPage from './pages/RegisterPage/RegisterPage'
import ProfilePage from './pages/ProfilePage/ProfilePage'
import AdminPage from './pages/AdminPage/AdminPage'
import RememberPage from './pages/RememberPage/RememberPage'
import LangHelper from './helpers/LangHelper/LangHelper'
import AboutPage from './pages/AboutPage/AboutPage'
import CampersPage from './pages/CampersPage/CampersPage'
import TripsPage from './pages/TripsPage/TripsPage'
import BlogPage from './pages/BlogPage/BlogPage'
import ContactPage from './pages/ContactPage/ContactPage'
import CamperPage from './pages/CamperPage/CamperPage'
import PostPage from './pages/PostPage/PostPage'
import AddCamperPage from './pages/AddCamperPage/AddCamperPage'
import AddPostPage from './pages/AddPostPage/AddPostPage'
import EditCamperPage from './pages/EditCamperPage/EditCamperPage'
import EditPostPage from './pages/EditPostPage/EditPostPage'
import EditReservationPage from './pages/EditReservationPage/EditReservationPage'
import AddGalleryPage from './pages/AddGalleryPage/AddGalleryPage'
import EditGalleryPage from './pages/EditGalleryPage/EditGalleryPage'
import AddTripPage from './pages/AddTripPage/AddTripPage'
import EditTripPage from './pages/EditTripPage/EditTripPage'
import TripPage from './pages/TripPage/TripPage'
import GalleryPage from './pages/GalleryPage/GalleryPage'

const App = () => {
  LangHelper.setDefaultLanguage()
  return (
    <Router basename={'/'}>
      <Switch>
        <PublicRoute exact path={'/'} component={() => <Redirect to={'/home'}/>} layout={SimpleLayout}/>
        <PublicRoute path={'/home'} component={HomePage} layout={PreviewLayout}/>
        <PublicRoute path={'/about'} component={AboutPage} layout={PreviewLayout}/>
        <PublicRoute path={'/campers'} component={CampersPage} layout={PreviewLayout}/>
        <PublicRoute path={'/camper'} component={CamperPage} layout={PreviewLayout}/>
        <PrivateRoute path={'/addCamper'} component={AddCamperPage} layout={MainLayout}/>
        <PrivateRoute path={'/editCamper/:id'} component={EditCamperPage} layout={MainLayout}/>
        <PublicRoute path={'/trips'} component={TripsPage} layout={PreviewLayout}/>
        <PublicRoute path={'/blog'} component={BlogPage} layout={PreviewLayout}/>
        <PublicRoute path={'/post/:id'} component={PostPage} layout={PreviewLayout}/>
        <PrivateRoute path={'/editPost/:id'} component={EditPostPage} layout={MainLayout}/>
        <PrivateRoute path={'/addPost'} component={AddPostPage} layout={MainLayout}/>
        <PrivateRoute path={'/addGallery'} component={AddGalleryPage} layout={MainLayout}/>
        <PrivateRoute path={'/editGallery/:id'} component={EditGalleryPage} layout={MainLayout}/>
        <PrivateRoute path={'/editReservation/:id'} component={EditReservationPage} layout={MainLayout}/>
        <PrivateRoute path={'/addTrip'} component={AddTripPage} layout={MainLayout}/>
        <PrivateRoute path={'/editTrip/:id'} component={EditTripPage} layout={MainLayout}/>
        <PublicRoute path={'/trip/:id'} component={TripPage} layout={PreviewLayout}/>
        <PublicRoute path={'/contact'} component={ContactPage} layout={PreviewLayout}/>
        <PublicRoute path={'/gallery'} component={GalleryPage} layout={PreviewLayout}/>
        <PublicRoute path={'/login'} component={LoginPage} layout={SimpleLayout}/>
        <PublicRoute path={'/register'} component={RegisterPage} layout={SimpleLayout}/>
        <PublicRoute path={'/reset'} component={RememberPage} layout={SimpleLayout}/>
        <PrivateRoute path={'/admin'} component={AdminPage} layout={MainLayout}/>
        <PrivateRoute path={'/profile'} component={ProfilePage} layout={MainLayout}/>
      </Switch>
    </Router>
  )
}

export default App
