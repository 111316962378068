import FormGenerator from '../../components/organisms/FormGenerator/FormGenerator'
import { useHistory } from 'react-router'
import { useEffect, useState } from 'react'
import BreakpointsHelper from '../../helpers/BreakpointsHelper/BreakpointsHelper'
import { createOrUpdateGalleries, getGalleryById } from '../../firebase'
import Typography from '@mui/material/Typography'
import { Grid } from '@mui/material'
import { DeleteOutlined } from '@mui/icons-material'
import Box from '@mui/material/Box'
import DomainHelper from '../../helpers/DomainHelper/DomainHelper'
import favicon from '../../assets/configFiles/favicon.png'
import favicon2 from '../../assets/configFiles/favicon2.png'
import { Helmet } from 'react-helmet'

const EditGalleryPage = () => {
  const idGallery = window.location.pathname.split('/')[2]
  const history = useHistory()
  const [formData, setFormData] = useState(null)
  const [data, setData] = useState(null)
  const style = {
    root: {
      width: BreakpointsHelper.isMobile() ? 'calc(100% - 30px)' : 'calc(100% - 110px)',
      padding: BreakpointsHelper.isMobile() ? '15px' : '20px 50px'
    }
  }
  const getFormData = () => {
    const tempData = {
      elements: [
        {
          name: 'name',
          type: 'text',
          value: data.name,
          label: 'Nazwa galerii'
        },
        {
          name: 'type',
          type: 'select',
          items: [{ name: 'Wyprawy', value: 'Wyprawy' }, { name: 'Kampery', value: 'Kampery' }],
          value: data?.type || '',
          label: 'Typ'
        },
        {
          name: 'date',
          type: 'date',
          value: data.date,
          label: 'Data'
        },
        {
          name: 'visibility',
          type: 'checkbox',
          value: data.visibility,
          label: 'Widoczność'
        },
        {
          name: 'files',
          type: 'files',
          value: data.files,
          label: 'Dodawanie zdjęć'
        },
        {
          type: 'button',
          value: 'Zapisz posta'
        }
      ]
    }
    setFormData(tempData)
  }
  const updateGallery = (e) => {
    createOrUpdateGalleries(e, idGallery).then((res) => {
      if (res) history.push('/admin?updateGallery=true')
    })
  }
  const deleteElement = (element) => {
    const tempData = data
    const tempFiles = []
    data?.files?.forEach(el => {
      if (el !== element) {
        tempFiles.push(el)
      }
    })
    tempData.files = tempFiles
    createOrUpdateGalleries(tempData, idGallery).then((res) => {
      if (res) history.push('/admin?deleteImageFromGallery=true')
    })
  }
  useEffect(() => {
    if (idGallery?.length > 0) {
      getGalleryById(idGallery).then((res) => res && setData(res))
    }
  }, [idGallery])
  useEffect(() => {
    if (data) {
      getFormData()
    }
  }, [data])
  return (
    <>
      <Helmet>
        <meta charSet="utf-8"/>
        <title>
          {DomainHelper.isAp() ? 'ApCamper - wyprawy kamperowe, Wynajem kamperów Koszalin' : 'Adventurepeople - sport, przygoda i wyprawy'}
        </title>
        <link
          rel="icon"
          href={DomainHelper.isAp() ? favicon : favicon2}
        />
      </Helmet>
      <div style={style.root}>
        {formData && data && <FormGenerator data={formData} submit={(e) => updateGallery(e)}/>}
        <Typography sx={{ fontSize: '22px', fontWeight: '600', marginBottom: '50px', marginTop: '50px' }}>Dodane
          zdjęcia</Typography>
        <Grid container spacing={4}>
          {
            data?.files?.map((el, index) => {
              return (
                <Grid item md={3} key={index}>
                  <Box sx={{ position: 'relative' }}>
                    <img src={el} alt={index} style={{ width: '100%', objectFit: 'cover', height: '200px' }}/>
                    <Box onClick={() => deleteElement(el)} sx={{
                      background: 'red',
                      padding: '10px',
                      position: 'absolute',
                      right: '10px',
                      top: '10px',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      cursor: 'pointer',
                      borderRadius: '50%'
                    }}>
                      <DeleteOutlined sx={{ fill: 'white' }}/>
                    </Box>
                  </Box>
                </Grid>
              )
            })
          }
        </Grid>
      </div>
    </>
  )
}

export default EditGalleryPage
