import HeadingPage from '../../components/atoms/HeadingPage/HeadingPage'
import { Grid, Typography } from '@mui/material'
import camper from '../../assets/images/e8811ba0c60b32e9f6f4207a12499e0a.jpg'
import camper2 from '../../assets/images/nXIUoL.jpg'
import camper3 from '../../assets/images/rving.webp'
import camper4 from '../../assets/images/asfafgassagsg.jpg'
import camper5 from '../../assets/images/image_processing20180829-4-1pycysh.jpg'
import camper6 from '../../assets/images/travel-newsletter-JWT-Sports-1024x683.jpg'
import BreakpointsHelper from '../../helpers/BreakpointsHelper/BreakpointsHelper'
import DomainHelper from '../../helpers/DomainHelper/DomainHelper'
import favicon from '../../assets/configFiles/favicon.png'
import favicon2 from '../../assets/configFiles/favicon2.png'
import { Helmet } from 'react-helmet'

const AboutPage = () => {
  const style = {
    root: {
      margin: '100px 0',
      width: BreakpointsHelper.isMobile() ? 'calc(100% - 30px)' : 'calc(100% - 110px)',
      padding: BreakpointsHelper.isMobile() ? '15px' : '20px 50px'
    }
  }
  return (
    <>
      <Helmet>
        <meta charSet="utf-8"/>
        <title>
          {DomainHelper.isAp() ? 'ApCamper - wyprawy kamperowe, Wynajem kamperów Koszalin' : 'Adventurepeople - sport, przygoda i wyprawy'}
        </title>
        <link
          rel="icon"
          href={DomainHelper.isAp() ? favicon : favicon2}
        />
      </Helmet>
      <HeadingPage
        small={DomainHelper.isAp() ? 'Znajdź z nami swojego kampera' : 'Znajdź z nami swoją wyprawę'}
        name="Jesteśmy tutaj dla Ciebie! Ważne dla nas, by klient był zadowolony"
      />
      <div style={style.root}>
        <Grid container spacing={4}>
          <Grid item md={6}>
            <Typography sx={{ fontWeight: '900', fontSize: '45px', paddingLeft: '20px' }}>
              Parę słow o nas
            </Typography>
            {
              DomainHelper.isAp() && (
                <Typography sx={{ maxWidth: '90%', fontSize: '25px', paddingLeft: '20px' }}>
                  ApCamper to firma specjalizująca się w wynajmowaniu kamperów w Koszalinie i okolicach. Firma oferuje
                  szeroki wybór kamperów różnych klas i rozmiarów, które są w pełni wyposażone i gotowe do podróży.
                  Dzięki
                  temu, klienci firmy mogą cieszyć się swobodą podróżowania bez konieczności posiadania własnego
                  samochodu
                  kempingowego.
                  <br/><br/>
                  ApCamper stawia na wysoką jakość świadczonych usług, dlatego kampery oferowane przez firmę są w pełni
                  sprawne i bezpieczne. Firma regularnie przeprowadza przeglądy techniczne oraz dba o czystość i higienę
                  wewnątrz pojazdów. Kampery są wyposażone w niezbędne akcesoria, takie jak kuchenki, lodówki, łazienki
                  oraz
                  namioty.
                  <br/><br/>
                  Firma ApCamper oferuje swoje usługi zarówno klientom indywidualnym, jak i firmowym. Dla klientów
                  indywidualnych wynajem kampera może być doskonałą opcją na wakacje lub weekendowy wypad poza miasto.
                  Dla
                  firm natomiast, wynajem kampera może być idealnym rozwiązaniem na potrzeby transportu pracowników lub
                  organizacji wyjazdów integracyjnych.
                  <br/><br/>
                  Wynajem kamperów w ApCamper jest bardzo prosty i przejrzysty. Klienci mogą dokonać rezerwacji online
                  lub
                  bezpośrednio w biurze firmy. W przypadku rezerwacji online, klienci mają dostęp do pełnej informacji
                  na
                  temat kamperów oraz dostępnych terminów. Firma oferuje również dodatkowe usługi, takie jak wynajem
                  rowerów, grillów czy też organizację wypraw i imprez.
                  <br/><br/>
                  ApCamper to firma z doświadczeniem oraz z pasją do podróżowania. Dzięki temu, firma potrafi doradzić
                  swoim
                  klientom, który kamper będzie dla nich najlepszy oraz jakie trasy podróży warto zaznaczyć na mapie.
                  ApCamper to także gwarancja bezpiecznej i komfortowej podróży, która na długo pozostanie w pamięci.
                </Typography>
              )
            }
            {
              DomainHelper.isAd() && (
                <Typography sx={{ maxWidth: '90%', fontSize: '25px', paddingLeft: '20px' }}>
                  Adventurepeople to firma turystyczna specjalizująca się w organizacji wyjątkowych wypraw krajowych
                  i
                  międzynarodowych. Naszą misją jest tworzenie niezapomnianych wspomnień dla każdego podróżnika, który
                  pragnie odkrywać nowe miejsca i kultury. Dzięki bogatemu doświadczeniu w branży turystycznej
                  zapewniamy
                  naszym klientom bezpieczne, komfortowe i autentyczne doświadczenia podczas podróży.
                  <br/><br/>
                  <b>Nasza oferta:</b>
                  <br/>
                  <b>• Wyprawy krajowe:</b> Odkrywaj piękno Polski z nami! Organizujemy wyprawy do
                  najpopularniejszych miejsc
                  oraz mniej znanych zakątków naszego kraju. Góry, morze, jeziora czy urokliwe miasteczka - z nami
                  zobaczysz je wszystkie.<br/>
                  <b>• Wyprawy międzynarodowe:</b> Chcesz poczuć atmosferę egzotycznych miejsc? Z nami
                  przemierzysz kontynenty i
                  odkryjesz najpiękniejsze zakątki świata.<br/>
                  <b>• Wypożyczalnia kamperów:</b> Dla tych, którzy pragną samodzielnie zaplanować swoją podróż,
                  oferujemy
                  możliwość wynajmu nowoczesnych kamperów. Oferowane przez nas pojazdy są w pełni wyposażone i gotowe na
                  drogowe przygody. Z nami masz pewność, że Twoja podróż będzie komfortowa.
                </Typography>
              )
            }
          </Grid>
          <Grid item md={6}>
            {
              DomainHelper?.isAp() && (
                <>
                  <img src={camper} alt="camper" style={{ width: '100%', marginBottom: '20px' }}/>
                  <img src={camper2} alt="camper" style={{ width: '100%', marginBottom: '20px' }}/>
                  <img src={camper3} alt="camper" style={{ width: '100%', marginBottom: '20px' }}/>
                </>
              )
            }
            {
              DomainHelper?.isAd() && (
                <>
                  <img src={camper4} alt="camper" style={{ width: '100%', marginBottom: '20px' }}/>
                  <img src={camper5} alt="camper" style={{ width: '100%', marginBottom: '20px' }}/>
                  <img src={camper6} alt="camper" style={{ width: '100%', marginBottom: '20px' }}/>
                </>
              )
            }
          </Grid>
        </Grid>
      </div>
    </>
  )
}

export default AboutPage
