import HeadingPage from '../../components/atoms/HeadingPage/HeadingPage'
import Campers from '../../components/molecules/Campers/Campers'
import DomainHelper from '../../helpers/DomainHelper/DomainHelper'
import favicon from '../../assets/configFiles/favicon.png'
import favicon2 from '../../assets/configFiles/favicon2.png'
import { Helmet } from 'react-helmet'

const CampersPage = () => {
  return (
    <>
      <Helmet>
        <meta charSet="utf-8"/>
        <title>
          {DomainHelper.isAp() ? 'ApCamper - wyprawy kamperowe, Wynajem kamperów Koszalin' : 'Adventurepeople - sport, przygoda i wyprawy'}
        </title>
        <link
          rel="icon"
          href={DomainHelper.isAp() ? favicon : favicon2}
        />
      </Helmet>
      <HeadingPage
        small="Wybierz kampera dla siebie"
        name="Wynajem kampera u nas to dosłownie parę kroków"
      />
      <Campers space/>
    </>
  )
}

export default CampersPage
