import HeadingPage from '../../components/atoms/HeadingPage/HeadingPage'
import Offers from '../../components/molecules/Offers/Offers'
import DomainHelper from '../../helpers/DomainHelper/DomainHelper'
import favicon from '../../assets/configFiles/favicon.png'
import favicon2 from '../../assets/configFiles/favicon2.png'
import { Helmet } from 'react-helmet'

const TripsPage = () => {
  return (
    <>
      <Helmet>
        <meta charSet="utf-8"/>
        <title>
          {DomainHelper.isAp() ? 'ApCamper - wyprawy kamperowe, Wynajem kamperów Koszalin' : 'Adventurepeople - sport, przygoda i wyprawy'}
        </title>
        <link
          rel="icon"
          href={DomainHelper.isAp() ? favicon : favicon2}
        />
      </Helmet>
      <HeadingPage
        small="Wybierz wyprawę dla siebie"
        name="Marzysz wybrać się gdzieś daleko, zaplanuj z nami swoje wakacje"
      />
      <Offers space/>
    </>
  )
}

export default TripsPage
