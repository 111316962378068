import { Box } from '@mui/material'
import loader from '../../../assets/images/loaderAd.gif'
import { useEffect, useState } from 'react'
import './MagicLoaderAd.scss'

const MagicLoaderAd = (props) => {
  const [isLoading, setIsLoading] = useState(true)
  const style = {
    root: {
      position: 'absolute',
      zIndex: 999,
      width: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      background: '#fffced',
      height: '100vh',
      top: '0',
      overflow: 'hidden'
    }
  }
  useEffect(() => {
    setIsLoading(true)
    const showLoading = setTimeout(() => {
      setIsLoading(false)
    }, 1000)
    return () => clearTimeout(showLoading)
  }, [])
  return (
    <>
      {
        isLoading
          ? (
            <Box className="MagicLoader" sx={style.root}>
              <img src={loader} alt="loader"/>
            </Box>
          )
          : (
            <>
              {props.children}
            </>
          )
      }
    </>
  )
}

export default MagicLoaderAd
