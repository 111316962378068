import BreakpointsHelper from '../../helpers/BreakpointsHelper/BreakpointsHelper'
import FormGenerator from '../../components/organisms/FormGenerator/FormGenerator'
import { useEffect, useState } from 'react'
import { useHistory } from 'react-router'
import Typography from '@mui/material/Typography'
import { createOrUpdateCamper } from '../../firebase'
import DomainHelper from '../../helpers/DomainHelper/DomainHelper'
import favicon from '../../assets/configFiles/favicon.png'
import favicon2 from '../../assets/configFiles/favicon2.png'
import { Helmet } from 'react-helmet'

const AddCamperPage = () => {
  const history = useHistory()
  const style = {
    root: {
      width: BreakpointsHelper.isMobile() ? 'calc(100% - 30px)' : 'calc(100% - 110px)',
      padding: BreakpointsHelper.isMobile() ? '15px' : '20px 50px'
    }
  }
  const [formData, setFormData] = useState(null)
  const getFormData = () => {
    const tempData = {
      elements: [
        {
          name: 'name',
          type: 'text',
          label: 'Marka samochodu'
        },
        {
          name: 'year',
          type: 'number',
          label: 'Rok produkcji'
        },
        {
          name: 'vin',
          type: 'text',
          label: 'Numer VIN'
        },
        {
          name: 'image',
          type: 'text',
          label: 'Zdjęcie domyślne'
        },
        {
          name: 'pricePerDaySummerHeight',
          type: 'number',
          value: 600,
          label: 'Cena brutto za dobę',
          helperText: 'Sezon wysoki (lipiec, sierpień). Wynajem min. 7 dni. Zawsze najem od piątku do piątku. Wydanie auta 15:00 - 16:00, a zdanie do 10:00.'
        },
        {
          name: 'pricePerDaySummerLow',
          type: 'number',
          value: 500,
          label: 'Cena brutto za dobę',
          helperText: 'Sezon niski (maj, czerwiec, wrzesień). Wydanie auta 15:00 - 16:00, a zdanie do 10:00.'
        },
        {
          name: 'pricePerDay',
          type: 'number',
          value: 400,
          label: 'Cena za dzień regularna',
          helperText: 'Poza sezonem (styczeń, luty, marzec, kwiecień, październik, listopad, grudzień). Wydanie auta 15:00 - 16:00, a zdanie do 10:00.'
        },
        {
          name: 'deposit',
          type: 'number',
          value: 4500,
          label: 'Wysokość kaucji'
        },
        {
          name: 'paymentForService',
          type: 'number',
          value: 300,
          label: 'Opłata serwisowa'
        },
        {
          name: 'paymentForAnimals',
          type: 'number',
          value: 200,
          label: 'Opłata za zwierzęta'
        },
        {
          name: 'smokingPenalty',
          type: 'number',
          value: 1500,
          label: 'Kara za palenie'
        },
        {
          name: 'notSubstitutingTheCar',
          type: 'number',
          value: 150,
          label: 'Kara za niepodstawienie auta o danej godzinie',
          helperText: 'Kara za każdą spóźniona godzinę'
        },
        {
          name: 'mileage',
          type: 'number',
          label: 'Przebieg w (km)'
        },
        {
          name: 'color',
          type: 'text',
          label: 'Kolor nadwozia'
        },
        {
          name: 'capacity',
          type: 'text',
          label: 'Pojemność w (l)'
        },
        {
          name: 'placesToRide',
          type: 'number',
          label: 'Ilość miejsc do jazdy'
        },
        {
          name: 'placesToSleep',
          type: 'number',
          label: 'Ilość miejsc do spania'
        },
        {
          name: 'capacityCar',
          type: 'number',
          label: 'Ładowność w (kg)'
        },
        {
          name: 'length',
          type: 'number',
          label: 'Długość w (mm)'
        },
        {
          name: 'transmission',
          type: 'select',
          items: [{ value: 'Manualna', name: 'Manualna' }, { value: 'Automatyczna', name: 'Automatyczna' }],
          label: 'Skrzynia biegów'
        },
        {
          name: 'additionalTechnicalData',
          type: 'editor',
          label: 'Dane techniczne dodatkowe'
        },
        {
          name: 'description',
          type: 'editor',
          label: 'Opis (wyposażenie, pakiety)'
        },
        {
          name: 'accessories',
          type: 'editor',
          label: 'Akcesoria dodatkowe'
        },
        {
          name: 'image1',
          type: 'text',
          label: 'Zdjęcie do galerii 1'
        },
        {
          name: 'image2',
          type: 'text',
          label: 'Zdjęcie do galerii 2'
        },
        {
          name: 'image3',
          type: 'text',
          label: 'Zdjęcie do galerii 3'
        },
        {
          name: 'image4',
          type: 'text',
          label: 'Zdjęcie do galerii 4'
        },
        {
          name: 'image5',
          type: 'text',
          label: 'Zdjęcie do galerii 5'
        },
        {
          name: 'image6',
          type: 'text',
          label: 'Zdjęcie do galerii 6'
        },
        {
          name: 'image7',
          type: 'text',
          label: 'Zdjęcie do galerii 7'
        },
        {
          name: 'image8',
          type: 'text',
          label: 'Zdjęcie do galerii 8'
        },
        {
          name: 'image9',
          type: 'text',
          label: 'Zdjęcie do galerii 9'
        },
        {
          name: 'image10',
          type: 'text',
          label: 'Zdjęcie do galerii 10'
        },
        {
          type: 'button',
          value: 'Zapisz kampera'
        }
      ]
    }
    setFormData(tempData)
  }
  const createCamper = (e) => {
    const { image } = e
    e.image = [image]
    createOrUpdateCamper(e).then((res) => {
      if (res) history.push('/admin?createCamper=true')
    })
  }
  useEffect(() => {
    getFormData()
  }, [])
  return (
    <>
      <Helmet>
        <meta charSet="utf-8"/>
        <title>
          {DomainHelper.isAp() ? 'ApCamper - wyprawy kamperowe, Wynajem kamperów Koszalin' : 'Adventurepeople - sport, przygoda i wyprawy'}
        </title>
        <link
          rel="icon"
          href={DomainHelper.isAp() ? favicon : favicon2}
        />
      </Helmet>
      <div style={style.root}>
        <Typography sx={{ fontSize: '25px' }}>
          Dodawnia nowego kampera
        </Typography>
        {formData && <FormGenerator data={formData} submit={(e) => createCamper(e)}/>}
      </div>
    </>
  )
}

export default AddCamperPage
