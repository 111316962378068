import { useEffect, useState } from 'react'
import { createOrUpdateReservations, getReservationById } from '../../firebase'
import BreakpointsHelper from '../../helpers/BreakpointsHelper/BreakpointsHelper'
import { Typography } from '@mui/material'
import DomainHelper from '../../helpers/DomainHelper/DomainHelper'
import favicon from '../../assets/configFiles/favicon.png'
import favicon2 from '../../assets/configFiles/favicon2.png'
import { Helmet } from 'react-helmet'

const EditReservationPage = () => {
  const id = window.location.pathname.split('/')[2]
  const [data, setData] = useState(null)
  const [formData, setFormData] = useState(null)
  const style = {
    root: {
      width: BreakpointsHelper.isMobile() ? 'calc(100% - 30px)' : 'calc(100% - 110px)',
      padding: BreakpointsHelper.isMobile() ? '15px' : '20px 50px'
    }
  }
  const getFormData = () => {
    const tempData = {
      elements: [
        {
          name: 'email',
          type: 'email',
          value: data?.email,
          label: 'Email'
        },
        {
          name: 'phone',
          type: 'tel',
          value: data?.phone,
          label: 'Telefon'
        },
        {
          name: 'dateStart',
          type: 'date',
          value: data?.dateStart,
          label: 'Date rozpoczęcia'
        },
        {
          name: 'dateEnd',
          type: 'date',
          value: data?.dateEnd,
          label: 'Date zakończenia'
        },
        {
          name: 'people',
          type: 'number',
          value: data?.people,
          label: 'Ilość osób'
        },
        {
          name: 'animals',
          type: 'checkbox',
          value: data?.animals,
          label: 'Czy bierzesz zwierzęta ?'
        },
        {
          name: 'linen',
          type: 'checkbox',
          value: data?.linen,
          label: 'Komplet pościeli'
        },
        {
          name: 'grill',
          type: 'checkbox',
          value: data?.grill,
          label: 'Grill gazowy'
        },
        {
          name: 'table',
          type: 'checkbox',
          value: data?.table,
          label: 'Stolik + 5 krzeseł z oparciem'
        }
      ]
    }
    setFormData(tempData)
  }
  const getData = () => getReservationById(id).then((res) => res && setData(res))
  const updateReservation = (e) => {
    e.price = data.price
    e.idCamper = data.idCamper
    createOrUpdateReservations(e, id).then((res) => {
      if (res) {
        window.open('/admin?updateReservation=true', '_self')
      }
    })
  }
  useEffect(() => {
    getData()
  }, [])
  useEffect(() => {
    if (data) {
      getFormData()
    }
  }, [data])
  return (
    <>
      <Helmet>
        <meta charSet="utf-8"/>
        <title>
          {DomainHelper.isAp() ? 'ApCamper - wyprawy kamperowe, Wynajem kamperów Koszalin' : 'Adventurepeople - sport, przygoda i wyprawy'}
        </title>
        <link
          rel="icon"
          href={DomainHelper.isAp() ? favicon : favicon2}
        />
      </Helmet>
      <div style={style.root}>
        {formData && data && (
          formData.elements.map((el, index) => (
            <Typography sx={{ fontSize: '25px', marginBottom: '10px' }} key={index}>
              <b>{el.label}:</b> {el.value === true ? 'Tak' : el.value === false ? 'Nie' : el.value?.length > 0 ? el.value : 'Nie'}
            </Typography>
          ))
        )}
      </div>
    </>
  )
}

export default EditReservationPage
