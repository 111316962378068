import { useEffect, useState } from 'react'
import Carousel from 'react-material-ui-carousel'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import BreakpointsHelper from '../../../helpers/BreakpointsHelper/BreakpointsHelper'
import Button from '@mui/material/Button'
import rightImage from '../../../assets/images/baner/right.png'
import rightImage2 from '../../../assets/images/baner/right2.png'
import leftImage from '../../../assets/images/baner/left.png'
import leftImage2 from '../../../assets/images/baner/left2.png'
import { useHistory } from 'react-router'
import { getDataFromPath } from '../../../firebase'
import MagicLoader from '../MagicLoader/MagicLoader'

const Slider = () => {
    const history = useHistory()
    const [data, setData] = useState(null)
    const getDataToBanner = () => {
        getDataFromPath('config', 'apcamper').then((res) => {
            if (res) {
                setData(res)
            }
        })
    }
    const [items, setItems] = useState([])
    const Item = (props) => {
        const el = props.item
        return (
            <Box
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    flexDirection: 'column',
                    width: '100%',
                    minHeight: 'calc(100vh - 120px - 130px)',
                    position: 'relative',
                    zIndex: 1,
                }}
            >
                <Typography
                    sx={{
                        textTransform: 'uppercase',
                        color: '#4592b3',
                        fontSize: '24px',
                        fontWeight: '600',
                        zIndex: 1
                    }}
                >
                    {el?.textUp}
                </Typography>
                <Typography
                    sx={{
                        fontSize: BreakpointsHelper.isMobile() ? '40px' : '60px',
                        maxWidth: '600px',
                        textAlign: 'center',
                        lineHeight: BreakpointsHelper.isMobile() ? '40px' : '60px',
                        marginTop: '10px',
                        marginBottom: '30px',
                        fontWeight: '900',
                        zIndex: 1
                    }}
                >
                    {el?.textCenter}
                </Typography>
                <Typography
                    sx={{
                        fontSize: '24px',
                        marginBottom: '30px',
                        zIndex: 1,
                        textAlign: 'center',
                        maxWidth: BreakpointsHelper.isMobile() ? '80%' : 'none'
                    }}
                >
                    {el?.textBottom}
                </Typography>
                <Button
                    className="button"
                    variant="contained"
                    id="about"
                    aria-label="Go to abous us"
                    onClick={() => history.push(el?.buttonLink)}
                    sx={{ zIndex: 1, minWidth: '200px', padding: '10px' }}
                >
                    {el?.textButton}
                </Button>
                <img
                    src={el?.right}
                    style={{
                        position: 'absolute',
                        zIndex: 0,
                        top: '50px',
                        right: '50px',
                        width: '450px',
                        filter: BreakpointsHelper.isMobile() && 'opacity(0.2)'
                    }}
                    alt="right image"
                />
                {
                    !BreakpointsHelper.isMobile() && (
                        <img
                            src={el?.left}
                            style={{
                                position: 'absolute',
                                zIndex: 0,
                                left: '50px',
                                width: '350px'
                            }}
                            alt="left image"
                        />
                    )
                }
            </Box>
        )
    }
    useEffect(() => {
        getDataToBanner()
    }, [])
    useEffect(() => {
        if (data) {
            const {
                textUp, textCenter, textBottom, textButton, buttonLink,
                textUp2, textCenter2, textBottom2, textButton2, buttonLink2,
            } = data
            const allItems = []
            allItems.push({
                textUp,
                textCenter,
                textBottom,
                textButton,
                buttonLink,
                left: leftImage,
                right: rightImage,
            })
            allItems.push({
                textUp: textUp2,
                textCenter: textCenter2,
                textBottom: textBottom2,
                textButton: textButton2,
                buttonLink: buttonLink2,
                left: leftImage2,
                right: rightImage2,
            })
            setItems(allItems)
        }
    }, [data])
    return (
        <MagicLoader variable={items?.length > 0}>
            <Carousel sx={{ width: 'calc(100% - 100px)', padding: '50px' }}>
                {
                    items?.length > 0 && items?.map((item, i) => <Item key={i} item={item}/>)
                }
            </Carousel>
        </MagicLoader>
    )
}

export default Slider
