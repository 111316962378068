import { Box, Grid, Typography } from '@mui/material'
import { useHistory } from 'react-router'

const PostsList = ({ data }) => {
  const history = useHistory()
  const style = {
    root: {
      padding: '20px',
      boxShadow: '2px 2px 20px 10px rgba(0, 0, 0, 0.07)',
      cursor: 'pointer',
      minHeight: '320px'
    },
    name: {
      fontSize: '24px',
      fontWeight: '600'
    },
    desc: {
      height: '210px',
      overflow: 'hidden',
      marginTop: '10px',
      whiteSpace: 'break-spaces'
    },
    img: {
      maxWidth: '100%',
      fitContent: 'cover'
    }
  }
  const openPost = (el) => history.push(`post/${el?.id}`)
  return (
    <Grid container spacing={4}>
      {
        data?.map((el, index) => {
          return (
            <Grid item md={6} xs={12} key={index}>
              <Box onClick={() => openPost(el)} sx={style.root}>
                <Grid container spacing={4}>
                  <Grid item md={4} xs={12}>
                    <img style={style.img} src={el?.image} alt={el?.name + ' post'}/>
                  </Grid>
                  <Grid item md={8} xs={12}>
                    <Typography sx={style.name}>{el?.name.toUpperCase()}</Typography>
                    <Typography sx={style.desc} dangerouslySetInnerHTML={{ __html: el?.description }}></Typography>
                  </Grid>
                </Grid>
              </Box>
            </Grid>
          )
        })
      }
    </Grid>
  )
}

export default PostsList
