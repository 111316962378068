import BreakpointsHelper from '../../helpers/BreakpointsHelper/BreakpointsHelper'
import FormGenerator from '../../components/organisms/FormGenerator/FormGenerator'
import { useEffect, useState } from 'react'
import { createOrUpdateCamper, getCamperById } from '../../firebase'
import { useHistory } from 'react-router'
import DomainHelper from '../../helpers/DomainHelper/DomainHelper'
import favicon from '../../assets/configFiles/favicon.png'
import favicon2 from '../../assets/configFiles/favicon2.png'
import { Helmet } from 'react-helmet'

const EditCamperPage = () => {
  const idCamper = window.location.pathname.split('/')[2]
  const history = useHistory()
  const [formData, setFormData] = useState(null)
  const [data, setData] = useState(null)
  const style = {
    root: {
      width: BreakpointsHelper.isMobile() ? 'calc(100% - 30px)' : 'calc(100% - 110px)',
      padding: BreakpointsHelper.isMobile() ? '15px' : '20px 50px'
    }
  }
  const getFormData = () => {
    const tempData = {
      elements: [
        {
          name: 'name',
          type: 'text',
          value: data?.name,
          label: 'Marka samochodu'
        },
        {
          name: 'rented',
          type: 'checkbox',
          value: data?.rented,
          label: 'Wynajęty'
        },
        {
          name: 'year',
          type: 'number',
          value: data?.year,
          label: 'Rok produkcji'
        },
        {
          name: 'vin',
          type: 'text',
          value: data?.vin,
          label: 'Numer VIN'
        },
        {
          name: 'image',
          type: 'text',
          value: data?.image[0],
          label: 'Zdjęcie domyślne'
        },
        {
          name: 'image1',
          type: 'text',
          value: data?.image1,
          label: 'Zdjęcie do galerii 1'
        },
        {
          name: 'image2',
          type: 'text',
          value: data?.image2,
          label: 'Zdjęcie do galerii 2'
        },
        {
          name: 'image3',
          type: 'text',
          value: data?.image3,
          label: 'Zdjęcie do galerii 3'
        },
        {
          name: 'image4',
          type: 'text',
          value: data?.image4,
          label: 'Zdjęcie do galerii 4'
        },
        {
          name: 'image5',
          type: 'text',
          value: data?.image5,
          label: 'Zdjęcie do galerii 5'
        },
        {
          name: 'image6',
          type: 'text',
          value: data?.image6,
          label: 'Zdjęcie do galerii 6'
        },
        {
          name: 'image7',
          type: 'text',
          value: data?.image7,
          label: 'Zdjęcie do galerii 7'
        },
        {
          name: 'image8',
          type: 'text',
          value: data?.image8,
          label: 'Zdjęcie do galerii 8'
        },
        {
          name: 'image9',
          type: 'text',
          value: data?.image9,
          label: 'Zdjęcie do galerii 9'
        },
        {
          name: 'image10',
          type: 'text',
          value: data?.image10,
          label: 'Zdjęcie do galerii 10'
        },
        {
          name: 'pricePerDaySummerHeight',
          type: 'number',
          value: data?.pricePerDaySummerHeight,
          label: 'Cena brutto za dobę',
          helperText: 'Sezon wysoki (lipiec, sierpień). Wynajem min. 7 dni. Zawsze najem od piątku do piątku. Wydanie auta 15:00 - 16:00, a zdanie do 10:00.'
        },
        {
          name: 'pricePerDaySummerLow',
          type: 'number',
          value: data?.pricePerDaySummerLow,
          label: 'Cena brutto za dobę',
          helperText: 'Sezon niski (maj, czerwiec, wrzesień). Wydanie auta 15:00 - 16:00, a zdanie do 10:00.'
        },
        {
          name: 'pricePerDay',
          type: 'number',
          value: data?.pricePerDay,
          label: 'Cena za dzień regularna',
          helperText: 'Poza sezonem (styczeń, luty, marzec, kwiecień, październik, listopad, grudzień). Wydanie auta 15:00 - 16:00, a zdanie do 10:00.'
        },
        {
          name: 'deposit',
          type: 'number',
          value: data?.deposit,
          label: 'Wysokość kaucji'
        },
        {
          name: 'paymentForService',
          type: 'number',
          value: data?.paymentForService,
          label: 'Opłata serwisowa'
        },
        {
          name: 'paymentForAnimals',
          type: 'number',
          value: data?.paymentForAnimals,
          label: 'Opłata za zwierzęta'
        },
        {
          name: 'smokingPenalty',
          type: 'number',
          value: data?.smokingPenalty,
          label: 'Kara za palenie'
        },
        {
          name: 'notSubstitutingTheCar',
          type: 'number',
          value: data?.notSubstitutingTheCar,
          label: 'Kara za niepodstawienie auta o danej godzinie',
          helperText: 'Kara za każdą spóźniona godzinę'
        },
        {
          name: 'mileage',
          type: 'number',
          value: data?.mileage,
          label: 'Przebieg w (km)'
        },
        {
          name: 'color',
          type: 'text',
          value: data?.color,
          label: 'Kolor nadwozia'
        },
        {
          name: 'capacity',
          type: 'text',
          value: data?.capacity,
          label: 'Pojemność w (l)'
        },
        {
          name: 'placesToRide',
          type: 'number',
          value: data?.placesToRide,
          label: 'Ilość miejsc do jazdy'
        },
        {
          name: 'placesToSleep',
          type: 'number',
          value: data?.placesToSleep,
          label: 'Ilość miejsc do spania'
        },
        {
          name: 'capacityCar',
          type: 'number',
          value: data?.capacityCar,
          label: 'Ładowność w (kg)'
        },
        {
          name: 'length',
          type: 'number',
          value: data?.length,
          label: 'Długość w (mm)'
        },
        {
          name: 'transmission',
          type: 'select',
          items: [{ value: 'Manualna', name: 'Manualna' }, { value: 'Automatyczna', name: 'Automatyczna' }],
          value: data?.transmission,
          label: 'Skrzynia biegów'
        },
        {
          name: 'additionalTechnicalData',
          type: 'editor',
          value: data?.additionalTechnicalData,
          label: 'Dane techniczne dodatkowe'
        },
        {
          name: 'description',
          type: 'editor',
          value: data?.description,
          label: 'Opis (wyposażenie, pakiety)'
        },
        {
          name: 'accessories',
          type: 'editor',
          value: data?.accessories,
          label: 'Akcesoria dodatkowe'
        },
        {
          name: 'iframe',
          type: 'text',
          value: data?.iframe,
          label: 'Spacer 360 stopni'
        },
        {
          type: 'button',
          value: 'Zapisz kampera'
        }
      ]
    }
    setFormData(tempData)
  }
  const updateCamper = (e) => {
    const { image } = e
    e.image = [image]
    createOrUpdateCamper(e, idCamper).then((res) => {
      if (res) history.push('/admin?updateCamper=true')
    })
  }
  useEffect(() => {
    if (idCamper?.length > 0) {
      getCamperById(idCamper).then((res) => res && setData(res))
    }
  }, [idCamper])
  useEffect(() => {
    if (data) {
      getFormData()
    }
  }, [data])
  return (
    <>
      <Helmet>
        <meta charSet="utf-8"/>
        <title>
          {DomainHelper.isAp() ? 'ApCamper - wyprawy kamperowe, Wynajem kamperów Koszalin' : 'Adventurepeople - sport, przygoda i wyprawy'}
        </title>
        <link
          rel="icon"
          href={DomainHelper.isAp() ? favicon : favicon2}
        />
      </Helmet>
      <div style={style.root}>
        {formData && data && <FormGenerator data={formData} submit={(e) => updateCamper(e)}/>}
      </div>
    </>
  )
}

export default EditCamperPage
